import React, { useEffect, useState } from 'react'
import TaskCheck from '../../hooks/read/taskCheck';
function Status({ status, taskId }) {
    const [refundedAmount, setRefundedAmount] = useState(false)
    const { taskCheck } = TaskCheck()
    const taskChekHandler = async () => {
        // console.log(taskData.contractTaskId, 'taskData?.contractTaskId');
        try {
            let value = await taskCheck(taskId)
            // console.log(value, 'valuevaluevalue');
            if (value) {
                setRefundedAmount(value)
            }
        }
        catch (err) {
            console.log(err, 'contract err');
        }
    }
    useEffect(() => {
        taskId && taskChekHandler()
    }, [taskId])
    return (
        <>
            {refundedAmount?.refunded ?
                <p className="tablepara"><span className={"endedbtn"}>{`Ended`}</span>
                </p>
                : <p className="tablepara"><span className={status == "In Progress" ? "progressbtn" : status == "Ended" ? "endedbtn" : status == "In Review" ? "reviewbtn" : 'disputedbtn'}>{status}</span>
                </p>}
        </>
    )
}
export default Status