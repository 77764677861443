

import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageResize from "quill-image-resize-module-react";

// Register the image resize module
Quill.register("modules/imageResize", ImageResize);

const TextEditor = ({ value, setValue }) => {

  // Define custom modules and toolbar
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image", "video"],
      ["link",],
      ["clean"], // Remove formatting button
      [{ align: [] }],
    ],
    imageResize: {
      displaySize: true,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "video",
    "align",
  ];

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        formats={formats}
        placeholder="Enter task description here..."
      />
    </div>
  );
};

export default TextEditor;
