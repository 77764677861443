import React, { useEffect, useState } from 'react'
import './tasksdetails.scss'
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Navbar from '../landing/header/Navbar';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utils/ApiUrl';
import moment from 'moment'
import { slice } from 'lodash';
import TaskTimer from '../Similartaskdetail/TaskTimer';
import ReactPaginate from "react-paginate";
import { toast } from 'react-toastify';
import RefundTask from '../../hooks/write/refundTask';
import TaskCheck from '../../hooks/read/taskCheck';
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import useSignature from "../../hooks/userSign1";
import Loader from '../../hooks/loader';
import RedeemTask1 from '../../hooks/write/redeemTask1';
const Tasksdetails = () => {
    const { redeemTask1 } = RedeemTask1()
    const { account } = useWeb3React();
    const { userSign } = useSignature();
    const { refundTask } = RefundTask()
    const { taskCheck } = TaskCheck()
    const web3 = useWeb3();
    const [proveOfWorkUser, setProveOfWorkUser] = useState(null)
    const [loader, setLoader] = useState(false);
    const [taskData, setTaskData] = useState(null); // State for storing fetched task data
    const [taskData1, setTaskData1] = useState(null); // State for storing fetched task data
    const { id } = useParams(); // Get the task ID from the URL
    const [timeshow, setTimeshow] = useState(false);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);

    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [arry, setArry] = useState([])
    const [TimerCheck, setTimerCheck] = useState({})
    const [refundedAmount, setRefundedAmount] = useState(false)
    const [contractId, setContractId] = useState(null)
    const [creater, setCreat] = useState(null)
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            const time = new Date("Dec 20, 2024 08:00:00");
            const diff = time.getTime() - now.getTime();
            if (diff <= 0) {
                clearInterval(interval);
                setTimeshow(true);
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);

            // Calculate the total seconds for the initial time
            const initialTotalSecs = 97 * 3600 + 97 * 60 + 97;
            const totalSecs = days * 24 * 3600 + hours * 3600 + mins * 60 + secs + initialTotalSecs;

            // Convert total seconds back to days, hours, minutes, and seconds
            const updatedDays = Math.floor(totalSecs / (24 * 3600));
            const updatedHours = Math.floor((totalSecs % (24 * 3600)) / 3600);
            const updatedMins = Math.floor((totalSecs % 3600) / 60);
            const updatedSecs = Math.floor(totalSecs % 60);

            setDay(updatedDays);
            setHour(updatedHours);
            setMin(updatedMins);
            setSec(updatedSecs);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const fetchTaskData = async () => {
        setLoader(true);
        try {
            // Get the accessToken from localStorage
            const accessToken = localStorage.getItem('accessToken');

            // Set up the config with headers
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}` // Add the token to the Authorization header
                }
            };

            // Make the API request with the Authorization header
            const response = await axios.get(`${API_URL}tasks/${id}`, config);
            // Store the fetched data in the state
            console.log(response?.data?.data, 'response?.data?.data');
            setTaskData(response?.data?.data);
            setTimerCheck(calculateTimeLeft(response?.data?.data))
            setLoader(false);

        } catch (error) {
            console.error("Error fetching task data:", error);
            setLoader(false);
        }
    };
    const fetchTaskData1 = async () => {
        let ids = parseInt(id)
        setLoader(true);
        try {
            // Get the accessToken from localStorage
            const accessToken = localStorage.getItem('accessToken');

            // Set up the config with headers
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}` // Add the token to the Authorization header
                }
            };

            // Make the API request with the Authorization header
            const response = await axios.get(`${API_URL}participants/${ids}?limit=${5}&offset=${page + 1}`, config);
            // Store the fetched data in the state

            // setTaskData1(response?.data?.data);
            let value = response?.data?.data
            setPageCount(value.count / 5);
            setPages(value.pages);
            setArry(value.participants)
            setContractId(value.contractTaskId)
            setLoader(false);
        } catch (error) {
            console.error("Error fetching task data:", error);
            setLoader(false);
        }
    };
    useEffect(() => {
        fetchTaskData()
        fetchTaskData1()
        window.scrollTo(0, 0)
    }, [id, page])
    useEffect(() => {
        taskChekHandler()
    }, [taskData])

    const clickme = (es) => {
        console.log(es, 'es');
        handleShow()
        setProveOfWorkUser(es)
    }

    const handlePageChange = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage);
    };
    const download = (e) => {
        const fileUrl = e; // Your file URL
        const fileName = "Task file"; // Desired file name
        window.open(fileUrl, '_blank')
        // Create an anchor element
        const link = document.createElement("a");
        // link.href = fileUrl;
        link.download = fileName;

        // Append the anchor to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by removing the anchor from the DOM
        document.body.removeChild(link);

    };

    const winnerFinal = async () => {
        // console.log(proveOfWorkUser?.user?.walletAddress, contractId, 'proveOfWorkUser');

        let v = await userSign(proveOfWorkUser?.user?.walletAddress, contractId)
        if (v) {
            let sigg = localStorage.getItem('sign')
            let accessToken = localStorage.getItem('accessToken')
            setLoader(true);
            var config = {
                method: "patch",
                url: `${API_URL}participants/${proveOfWorkUser.id}/winner`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`  // Include the access token here
                },
                data: {
                    "status": "winner",
                    "creatorSignature": v
                }

            }
            try {
                let response = await axios(config)

                if (response) {
                    handleClose1()
                    fetchTaskData()
                    setLoader(false);
                    handleShow2()

                }
            } catch (error) {
                console.error('Error occurred while creating task:', error);
                setLoader(false);
                toast.error(error.message)
            }

        }
    }

    function calculateTimeLeft(e) {
        const difference = new Date(e?.endTime) - new Date(); // Calculate time difference
        let timeRemaining = {};

        if (difference > 0) {
            timeRemaining = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            timeRemaining = false
        }

        return timeRemaining;
    }
    // console.log(TimerCheck, 'TimerCheck');

    const claimRefund = async () => {
        let signn = localStorage.getItem("sign")
        setLoader(true);
        // console.log(creater?.signedKeyDetails.taskId, creater?.signedKeyDetails.signerSignature, 'creater?.signedKeyDetails.taskId,creater?.signedKeyDetails.signerSignature');
        try {
            let valeu = await refundTask(creater?.signedKeyDetails.taskId, creater?.signedKeyDetails.signerSignature)
            if (valeu) {
                handleClose4()
                handleShow2()
                taskChekHandler()
            }
            setLoader(false);
        }
        catch (err) {
            setLoader(false);
        }
    }
    const taskChekHandler = async () => {
        // console.log(taskData.contractTaskId, 'taskData?.contractTaskId');
        setLoader(true);
        try {
            let value = await taskCheck(taskData?.contractTaskId)
            // console.log(value, 'valuevaluevalue');
            if (value) {
                setRefundedAmount(value)
            }
            setLoader(false);
        }
        catch (err) {
            console.log(err, 'contract err');
            setLoader(false);
        }
    }

    const apiCallHandler = async (e) => {

        let accessToken = localStorage.getItem('accessToken')
        let signg = localStorage.getItem('sign')
        setLoader(true);
        var config = {
            method: "get",
            url: `${API_URL}tasks/${taskData.id}/signature/refund`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },

        }
        try {
            let response = await axios(config)

            if (response) {
                // console.log('Task created successfully:', response.data.data);
                handleShow4()
                setCreat(response.data.data)
                taskChekHandler();
                setLoader(false);
                //    setWinnerData(e)
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
        }
    }



    const apiCallHandler1 = async (e) => {
        // setdatata(e)
        setLoader(true);
        // setSelectedIs(e?.id)
        let accessToken = localStorage.getItem('accessToken')
        let signg = localStorage.getItem('sign')
        var config = {
            method: "get",
            url: `${API_URL}tasks/${taskData.id}/signature/dispute`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },

        }
        try {
            let response = await axios(config)

            if (response) {
                console.log('Task created successfully:', response.data.data);
                // handleShow6()
                handleShow4()
                setCreat(response.data.data)
                setLoader(false);
                taskChekHandler()
                //    setWinnerData(e)
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
        }
    }


    const claimToken1 = async () => {
        console.log('dsadsdasdad');
        let sign = localStorage.getItem("sign")
        // console.log(winnerData?.task, 'sddsadasasd');\
        console.log(creater, sign, 'signsignsign');
        setLoader(true);
        try {
            let value = await redeemTask1(creater, sign)
            if (value) {
                handleClose4()
                handleShow2()
                taskChekHandler()
            }
            setLoader(false);
        } catch (err) {
            console.log(err, "contract erro");
            setLoader(false);
            toast.error("Something went wrong!");
        }
    }



    return (
        <>
            {loader && <Loader />}
            <Navbar />
            <section className="tasksdetails">
                <img src="/assets/importantassets/detailbackshade.png" alt="backshade" className="backshade" />
                <img src="/assets/importantassets/detailbackshadembl.png" alt="backshade" className="backshadembl d-none" />
                <div className="custom-container">
                    <p className="toppara">
                        <span className="light">Home </span>
                        / Design
                    </p>
                    <div className="detailmain">
                        <div className="detailsleft">
                            {/* <span className="statusmain">
                                <span className="statuscircle"></span>
                                <p className="statuspara">PARTICIPATED</p>
                            </span> */}
                              <h4 className="detailhead">{taskData?.title}</h4>
                            <div
                                className="detailpara"
                                // Uncomment the next line if using DOMPurify
                                // dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                                dangerouslySetInnerHTML={{ __html: taskData?.description }}
                            />



                            {/* <h4 className="detailhead">Follow my twitter handle and share my latest pinned tweet</h4>
                            <p className="detailpara">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas.</p>
                            <p className="detailpara">
                                Sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non.
                            </p>
                            <p className="detailpara">
                                Numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur
                            </p> */}
                        </div>
                        <div className="detailsright">
                            <div className="innerdetailsright">
                                <div className="detailtop">
                                    <div className="detailinnerleft">
                                        <p className="rewardpara">Reward</p>
                                        <div className="rewardamount">
                                            <div className="rewardimg">
                                                <img src={taskData?.currencySymbol === "TOMI" ? "assets/dummyassets/tomi.svg" : taskData?.currencySymbol === "ETH" ? "/assets/createtask/eth.svg" : taskData?.currencySymbol === "USDT" ? '/assets/createtask/usdtT.svg' : taskData?.currencySymbol === "WBTC" ? "/assets/createtask/wbtc.svg" : taskData?.currencySymbol === "DOP" ? '/assets/createtask/DOP.svg' : '/assets/createtask/usdc.svg'} alt="innerrewardimg" className="innerrewardimg" />
                                            </div>
                                            {taskData?.reward} {taskData?.currencySymbol}
                                        </div>
                                    </div>
                                    <div className="detailinnerright">
                                        <p className="participantpara">Participants</p>
                                        <div className="partcipantmainhead">
                                            {
                                                arry?.slice(0, 4).map((e, i) => {
                                                    let classn
                                                    if (i == 0) {
                                                        classn = 'one'
                                                    } else if ((i == 1)) {
                                                        classn = 'two'
                                                    } else if ((i == 2)) {
                                                        classn = 'three'
                                                    } else {
                                                        classn = 'four'
                                                    }
                                                    // if(i==3){
                                                    //     return
                                                    // }
                                                    return (
                                                        <div className={`participantmain ${classn}`}>
                                                            <img src={e?.user?.imageUrl ? e?.user?.imageUrl : '/assets/edituploadimg.png'} alt="participantimg" className="participantimg" />
                                                        </div>
                                                    )
                                                })}

                                            {/* <div className="participantmain two">
                                                <img src="/assets/dummyassets/dummytwo.png" alt="participantimg" className="participantimg" />
                                            </div>
                                            <div className="participantmain three">
                                                <img src="/assets/dummyassets/dummythree.png" alt="participantimg" className="participantimg" />
                                            </div>
                                            <div className="participantmain four">
                                                <img src="/assets/dummyassets/dummyfour.png" alt="participantimg" className="participantimg" />
                                            </div> */}
                                            {taskData?.participantCount > 4 &&
                                                <div className="participantmain five">
                                                    <p className="numberimages">
                                                        {`${taskData?.participantCount - 4}+`}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="maintimer">
                                    <p className="endpara">Ends in</p>
                                    {taskData?.participants[0]?.status === 'winner' ?
                                        <TaskTimer endTime={0}></TaskTimer>
                                        :
                                        <TaskTimer endTime={taskData?.endTime}></TaskTimer>
                                    }


                                </div>
                            </div>
                            {/* <div className="innerdetailsright">
                                <h6 className="reviewhead">Review</h6>
                                <p className="reviewpara">Remaining time to decide your winner for this task</p>
                                <div className="maintimer">
                                    {timeshow ? (
                                        <p>Timer has ended!</p>
                                    ) : (
                                        <div className="timerspans">
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{day ? day : 0}D</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{hour ? hour : 0}H</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{min ? min : 0}M</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime seconds">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{sec ? sec : 0}S</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <p className="redpara">
                                    This task is currently under DAO review
                                </p>
                            </div> */}


                            {!TimerCheck || taskData?.participants[0]?.status === 'winner' ?
                                <>

                                    {taskData?.participants[0]?.status === 'winner' ?
                                        <>
                                            <div className="innerdetailsright">
                                                <p className="reviewpara text-center">Creator has decided the following as winner</p>
                                                <div className="winnermain">
                                                    <p className="winnerpara">
                                                        Winner:
                                                    </p>

                                                    <div className="winnermainhead">
                                                        <div className="winnermainimg">
                                                            <img src={taskData?.participants[0]?.user?.imageUrl ? taskData?.participants[0]?.user?.imageUrl : "/assets/edituploadimg.png"} alt="winnerimg" className="winnerimg" />
                                                        </div>
                                                        <p className="winnerwallet">{taskData?.participants[0]?.user?.walletAddress?.slice(0, 5)}...{taskData?.participants[0]?.user?.walletAddress?.slice(-3)}</p>
                                                    </div>


                                                </div>
                                            </div>
                                        </>

                                        : <>

                                            {taskData?.status !== "disputed" ?
                                                <>
                                                    {!refundedAmount?.refunded ?
                                                        <>
                                                            {
                                                                (new Date(taskData?.coolDownPeriod) < new Date()) ?

                                                                    <>
                                                                        {taskData?.disputeWinnerId ?
                                                                            <div className="innerdetailsright">
                                                                                <h6 className="reviewhead">Results</h6>
                                                                                <p className="reviewpara text-center">DAO has decided to give you a refund</p>
                                                                                <button className="withdrawbtn" onClick={apiCallHandler1}>
                                                                                    Claim Reward
                                                                                </button>
                                                                            </ div>
                                                                            :

                                                                            <div className="innerdetailsright">
                                                                                <h6 className="reviewhead">Results</h6>
                                                                                <p className="reviewpara text-center">DAO has decided to give you a refund</p>
                                                                                <button className="withdrawbtn" onClick={apiCallHandler}>
                                                                                    Claim Reward
                                                                                </button>
                                                                            </ div>
                                                                        }
                                                                    </>

                                                                    :
                                                                    <div className="innerdetailsright">
                                                                        <h6 className="reviewhead">Review</h6>

                                                                        <div className="maintimer">

                                                                            <TaskTimer endTime={taskData?.coolDownPeriod} />
                                                                            {/* <TaskTimer endTime={taskData?.endTime} /> */}
                                                                        </div>
                                                                    </div>

                                                            }
                                                        </>

                                                        :
                                                        <div className="innerdetailsright">
                                                            <h6 className="reviewhead">Results</h6>

                                                            <button className="fundwithdrawnbtn">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
                                                                    <path d="M14.6483 1.46078L5.64828 10.4608C5.59604 10.5131 5.534 10.5546 5.46572 10.5829C5.39743 10.6112 5.32423 10.6258 5.25031 10.6258C5.17639 10.6258 5.1032 10.6112 5.03491 10.5829C4.96662 10.5546 4.90458 10.5131 4.85234 10.4608L0.914844 6.52328C0.809296 6.41773 0.75 6.27458 0.75 6.12531C0.75 5.97605 0.809296 5.83289 0.914844 5.72734C1.02039 5.6218 1.16355 5.5625 1.31281 5.5625C1.46208 5.5625 1.60523 5.6218 1.71078 5.72734L5.25031 9.26758L13.8523 0.664844C13.9579 0.559296 14.101 0.5 14.2503 0.5C14.3996 0.5 14.5427 0.559296 14.6483 0.664844C14.7538 0.770392 14.8131 0.913546 14.8131 1.06281C14.8131 1.21208 14.7538 1.35523 14.6483 1.46078Z" fill="#013159" />
                                                                </svg>
                                                                Funds Withdrawn
                                                            </button>
                                                        </div>
                                                    }
                                                </>
                                                :
                                                ""

                                            }

                                        </>

                                    }

                                </>
                                :
                                ""
                            }



                            {/* <p className="createdbypara">Created By</p>
                            <div className="creatormain">
                                <div className="creatormainimg">
                                    <img src="/assets/dummyassets/dummysix.png" alt="creatorimg" className="creatorimg" />
                                </div>
                                <p className="creatorwallet">0xa12s..14ws</p>
                            </div> */}
                        </div>
                    </div>
                    <h6 className="mainpartcipanthead">Submissions</h6>
                    <div className="createdtasktable">
                        {
                            arry.length > 0 ? <table>
                                <thead>
                                    <th>Name</th>
                                    <th>Date Participated</th>
                                    <th>Proof of Work</th>
                                </thead>
                                <tbody>
                                    {arry?.map((ee) => {
                                        const date = moment(ee.dateParticipated);
                                        const formattedDate = date.format("DD/MM/YYYY");
                                        return (
                                            <tr>
                                                <td>
                                                    <p className="tablepara">{ee?.user?.name ? ee?.user?.name : "anonymous"}</p>
                                                </td>
                                                <td>
                                                    <p className="tablepara">{formattedDate}</p>
                                                </td>
                                                <td>
                                                    <button onClick={() => clickme(ee)} disabled={refundedAmount?.refunded || refundedAmount?.redeemed ? true : taskData?.participants[0]?.status === 'winner' ? true : false} className={refundedAmount?.refunded || refundedAmount?.redeemed ? true : taskData?.participants[0]?.status === 'winner' ? 'viewpara disableviewparabtn' : 'viewpara btnwhenclickable'}>View Files</button>
                                                </td>
                                            </tr>
                                        )

                                    })
                                    }

                                </tbody>
                            </table> : <table>
                                <thead>
                                    <th>Name</th>
                                    <th>Date Participated</th>
                                    <th>Proof of Work</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <h6 className="nodata-text">No Data Found!</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        }

                        <div className="paginationmain">
                            {/* <div className="left-f">
          <h6>
                {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
          </div> */}
                            {pages > 1 &&
                                <ReactPaginate
                                    previousLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M10 13L5 8L10 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    nextLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M5 13L10 8L5 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={page}
                                ></ReactPaginate>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <Modal show={show} onHide={handleClose} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Proof of Work</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal3">

                        <div className="subnmatouterdiv">
                            <div className="">
                                <p className="submitted">
                                    Submitted By
                                </p>
                            </div>

                            <div className="secdiv">
                                <img src={proveOfWorkUser?.user?.imageUrl ? proveOfWorkUser?.user?.imageUrl : '/assets/edituploadimg.png'} className="inerimgg" />
                                <p className="namewithimg">
                                    {proveOfWorkUser?.user?.name ? proveOfWorkUser?.user?.name : "anonymous"}
                                </p>
                            </div>
                        </div>
                        <div className="msgdiv">
                            <h1 className="msghfdng">Message</h1>
                            <p className="inermsgpars">
                                {proveOfWorkUser?.message ? proveOfWorkUser?.message : ""}
                            </p>
                            <div className="mainscrooldiv">
                                {proveOfWorkUser?.proofOfWork?.map((e) => {
                                    return (
                                        <div className="inerscrooldiv">
                                            <p className="dddooeenger">
                                                {e?.slice(-10)?.split('.')[0]}.{e?.slice(-10)?.split('.')[1]}
                                            </p>
                                            <p className="dddooeengerright"
                                                onClick={() => { download(e) }}>
                                                <spam >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M17.5 11.25V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V11.25C2.5 11.0842 2.56585 10.9253 2.68306 10.8081C2.80027 10.6908 2.95924 10.625 3.125 10.625C3.29076 10.625 3.44973 10.6908 3.56694 10.8081C3.68415 10.9253 3.75 11.0842 3.75 11.25V15.625H16.25V11.25C16.25 11.0842 16.3158 10.9253 16.4331 10.8081C16.5503 10.6908 16.7092 10.625 16.875 10.625C17.0408 10.625 17.1997 10.6908 17.3169 10.8081C17.4342 10.9253 17.5 11.0842 17.5 11.25ZM9.55781 11.6922C9.61586 11.7503 9.68479 11.7964 9.76066 11.8279C9.83654 11.8593 9.91787 11.8755 10 11.8755C10.0821 11.8755 10.1635 11.8593 10.2393 11.8279C10.3152 11.7964 10.3841 11.7503 10.4422 11.6922L13.5672 8.56719C13.6253 8.50912 13.6713 8.44018 13.7027 8.36431C13.7342 8.28844 13.7503 8.20712 13.7503 8.125C13.7503 8.04288 13.7342 7.96156 13.7027 7.88569C13.6713 7.80982 13.6253 7.74088 13.5672 7.68281C13.5091 7.62474 13.4402 7.57868 13.3643 7.54725C13.2884 7.51583 13.2071 7.49965 13.125 7.49965C13.0429 7.49965 12.9616 7.51583 12.8857 7.54725C12.8098 7.57868 12.7409 7.62474 12.6828 7.68281L10.625 9.74141V2.5C10.625 2.33424 10.5592 2.17527 10.4419 2.05806C10.3247 1.94085 10.1658 1.875 10 1.875C9.83424 1.875 9.67527 1.94085 9.55806 2.05806C9.44085 2.17527 9.375 2.33424 9.375 2.5V9.74141L7.31719 7.68281C7.19991 7.56554 7.04085 7.49965 6.875 7.49965C6.70915 7.49965 6.55009 7.56554 6.43281 7.68281C6.31554 7.80009 6.24965 7.95915 6.24965 8.125C6.24965 8.29085 6.31554 8.44991 6.43281 8.56719L9.55781 11.6922Z" fill="#013159" />
                                                    </svg>
                                                </spam>
                                                Download


                                            </p>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>




                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        {/* <button className="cancelbutton" onClick={handleClose2}>CANCEL</button> */}
                        <button className="surebutton" onClick={() => {
                            handleClose()
                            handleShow1()
                        }}>Approve Claim</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Winner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="34" y="18" width="2" height="28" fill="#013159" />
                            <rect x="34" y="49" width="2" height="3" fill="#013159" />
                        </svg>
                        <h1>Are you confident about your decision?</h1>
                        {/* <p className="parssa">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose1}>CANCEL</button>
                        <button className="surebutton" onClick={() => {

                            winnerFinal()
                        }}>Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="28.2036" y="49.8564" width="2" height="40.2235" transform="rotate(-135 28.2036 49.8564)" fill="#013159" />
                            <rect x="17" y="38.625" width="2" height="15.43" transform="rotate(-45 17 38.625)" fill="#013159" />
                        </svg>
                        {taskData?.participantCount == 0 ?
                            <h1>Refund Successfully </h1>
                            :
                            <h1>Winner Successfully Selected</h1>
                        }

                        {/* <p className="parssa">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}

                    </div>
                    {taskData?.participantCount == 0 ?
                        ""
                        :
                        <div className="mainscrooldiv2nd">
                            <div className="inerscrooldiv2nd">
                                <p className="dddooeenger">
                                    Winner:
                                </p>
                                <div className="secdiv2nd">
                                    <img src={proveOfWorkUser?.user?.imageUrl ? proveOfWorkUser?.user?.imageUrl : '/assets/edituploadimg.png'} className="inerimgg" />
                                    <p className="namewithimg">
                                        {proveOfWorkUser?.user?.name ? proveOfWorkUser?.user?.name : "anonymous"}
                                    </p>
                                </div>
                            </div>

                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose2}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={show4} onHide={handleClose4} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Claim Reward</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="34" y="18" width="2" height="28" fill="#013159" />
                            <rect x="34" y="49" width="2" height="3" fill="#013159" />
                        </svg>
                        <h1>Are you sure you want to claim a refund <br />
                            {taskData?.reward} {taskData?.currency} for this task?</h1>
                        <p className="parssa">The reward sum will be transferred to your connected wallet.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose4}>Cancel</button>
                        <button className="surebutton" onClick={() => {
                            console.log('sdsddasdasdsa chala',taskData?.disputeWinnerId);
                            // claimToken() 
                            if(taskData?.disputeWinnerId){
                                claimToken1()
                            }else{
                                
                                claimRefund()
                            }
                        }}>Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Tasksdetails
