import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import "./createtask.scss";
import Navbar from "../landing/header/Navbar";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextEditor from "./TextEditor";
import { Modal } from "react-bootstrap";
import { API_URL } from "../../utils/ApiUrl";
import CreateTast from "../../hooks/write/crateTask";
import Allowance from "../../hooks/read/approveAllowance";
import { toast } from "react-toastify";
import Loader from "../../hooks/loader";
import { Link, useNavigate } from "react-router-dom";
import BalanceOf from "../../hooks/read/balanceOf";
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
const Createtask = () => {
    const history = useNavigate()
    let { BalanceOfHook }=BalanceOf()
    const [value, setValue] = useState(localStorage.getItem('description') || "");  // Task description value
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [loader, setLoader] = useState(false);
    const handleShow = () => setShow(true);
    const { createTast } = CreateTast()
    const { AllowanceCore } = Allowance()
    const { account } = useWeb3React();
    const web3 = useWeb3();
    // Form states
    const [title, setTitle] = useState(localStorage.getItem('title') || '');
    const [reward, setReward] = useState(localStorage.getItem('reward') || '');
    const [contact, setContact] = useState(localStorage.getItem('contact') || '');
    const [endDate, setEndDate] = useState(localStorage.getItem('endDate') || '');
    const [selectedCategory, setSelectedCategory] = useState(JSON.parse(localStorage.getItem('selectedCategory')) || '');
    const [selectedToken, setSelectedToken] = useState(JSON.parse(localStorage.getItem('selectedToken')) || {});

    // Error states
    const [errors, setErrors] = useState({});
    const [categories, setCategories] = useState([{
        categoryName: "Logistics & Supply Chain",
        id: 15
    }]); 
    const [tokens] = useState([
        {
            currency: "ETH",
            currencySymbol: "ETH",
            currencyAddress: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
            decimal: 18,
            type: 'coin',
            imgSrc: "/assets/createtask/eth.svg"
        },
        {
            currency: "USDT",
            currencySymbol: "USDT",
            currencyAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
            decimal: 6,
            type: 'token',
            imgSrc: "/assets/createtask/usdtT.svg"
        },
        {
            currency: "USDC",
            currencySymbol: "USDC",
            currencyAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            decimal: 6,
            type: 'token',
            imgSrc: "/assets/createtask/usdc.svg"
        },
        // {
        //     currency: "LINK",
        //     currencySymbol: "LINK",
        //     currencyAddress: "0x0000000000000000000000000000000000000003",
        //     imgSrc: "/assets/createtask/link.svg"
        // },
        // {
        //     currency: "PEPE",
        //     currencySymbol: "PEPE",
        //     currencyAddress: "0x0000000000000000000000000000000000000004",
        //     imgSrc: "/assets/createtask/pepe.svg"
        // },
        // {
        //     currency: "UNI",
        //     currencySymbol: "UNI",
        //     currencyAddress: "0x0000000000000000000000000000000000000005",
        //     imgSrc: "/assets/createtask/uni.svg"

        // },
        // {
        //     currency: "DOP",
        //     currencySymbol: "DOP",
        //     currencyAddress: "0xa048E46C35cf210bB0d5bb46b2DD06828Ef17893",
        //     decimal: 18,
        //     imgSrc: "/assets/createtask/dop1.svg",
        //     type: 'token',
        // },
        // {
        //     currency: "WBTC",
        //     currencySymbol: "WBTC",
        //     decimal: 8,
        //     currencyAddress: "0x54410d42628C7fD6720C489219033345Bcb7Cc73",
        //     imgSrc: "/assets/createtask/wbtc.svg",
        //     type: 'token',
        // }
    ]);
    // useEffect to persist form data to localStorage
    useEffect(() => {
        localStorage.setItem('title', title);
        localStorage.setItem('reward', reward);
        localStorage.setItem('contact', contact);
        localStorage.setItem('endDate', endDate);
        localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory));
        localStorage.setItem('selectedToken', JSON.stringify(selectedToken));
        localStorage.setItem('description', value);
    }, [title, reward, contact, endDate, selectedCategory, selectedToken, value]);

    // useEffect to load description from localStorage
    useEffect(() => {
        const savedDescription = localStorage.getItem('description');
        if (savedDescription) {
            setValue(savedDescription);
        }
    }, []);
    // Validate form fields
    const validate = () => {
        const newErrors = {};
        if (!title) newErrors.title = "Task title is required";
        if (!value) newErrors.description = "Description is required";
        if (!reward) newErrors.reward = "Reward amount is required";
        if (!selectedCategory) newErrors.category = "Category selection is required";
        if (!selectedToken.currencySymbol) newErrors.currency = "Currency selection is required";
        if (!endDate) newErrors.endDate = "End date is required";
        return newErrors;
    };

    // Handle real-time input validation
    const handleInputChange = (field, value) => {
        switch (field) {
            case 'title':
                setTitle(value);
                if (errors.title) {
                    setErrors((prev) => ({ ...prev, title: "" })); // Clear error when user types
                }
                break;
            case 'description':
                setValue(value);
                if (errors.description) {
                    setErrors((prev) => ({ ...prev, description: "" }));
                }
                break;
            case 'reward':
                setReward(value);
                if (errors.reward) {
                    setErrors((prev) => ({ ...prev, reward: "" }));
                }
                break;
                case 'contact':
                    setContact(value);
                    if (errors.contact) {
                        setErrors((prev) => ({ ...prev, contact: "" }));
                    }
                    break;
            case 'endDate':
                setEndDate(value);
                if (errors.endDate) {
                    setErrors((prev) => ({ ...prev, endDate: "" }));
                }
                break;
            default:
                break;
        }
    };

    // Handle real-time category selection and error removal
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        if (errors.category) {
            setErrors((prev) => ({ ...prev, category: "" })); // Clear category error when selected
        }
    };

    // Handle real-time token (currency) selection and error removal
    const handleTokenSelect = (token) => {
       
        setSelectedToken(token);
        if (errors.currency) {
            setErrors((prev) => ({ ...prev, currency: "" })); // Clear currency error when selected
        }
    };

    const handleSubmit = async () => {

        if (!account) {
            return toast.error('Please connect to your wallet');
        }

        // Validate form data
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            return setErrors(validationErrors); // Stop form submission if there are errors
        }

        let taskId;
        let taskTransactionHash;
        try {
            setLoader(true);
            let balance = await getBalance(selectedToken);

            if (parseFloat(balance) < parseFloat(reward)) {
                setLoader(false);
                return toast.error(`Balance must be greater than ${selectedToken.currencySymbol} reward amount`);
            }

            // Check allowance for non-ETH tokens
            if (selectedToken?.currencyAddress !== '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
                await AllowanceCore(selectedToken.currencyAddress, reward, selectedToken.decimal, selectedToken.currency);
            }

            // Contract call
            const epochTime = Math.floor(new Date(endDate).getTime() / 1000);
            console.log(epochTime, endDate, 'epochTimeepochTime');

            let res = await createTast(reward, title, value, reward, selectedToken.currencyAddress, selectedToken.decimal);
            taskId = res?.events?.TaskCreated?.returnValues?.taskId;
            taskTransactionHash = res?.transactionHash;
            console.log(taskId, 'resresresresres');
            setLoader(false);

        } catch (error) {
            handleContractError(error);
            setLoader(false);
            return; // Stop API call if contract call fails
        }

        // Proceed with API call if contract succeeds
        await saveTaskData(taskId, taskTransactionHash);
    };

    // Helper function to get the balance
    const getBalance = async (selectedToken) => {
        let balance;
        if (selectedToken?.decimal === 18 && selectedToken?.type === 'coin') {
            balance = await web3.eth.getBalance(account);
            return web3.utils.fromWei(balance);
        } else if (selectedToken?.decimal === 18) {
            balance = await BalanceOfHook(selectedToken.currencyAddress);
            return web3.utils.fromWei(balance);
        } else if (selectedToken?.decimal === 6) {
            balance = await BalanceOfHook(selectedToken.currencyAddress);
            return web3.utils.fromWei(balance.toString(), 'mwei');
        } else if (selectedToken?.decimal === 8) {
            balance = await BalanceOfHook(selectedToken.currencyAddress);
            return parseFloat(balance) / Math.pow(10, parseInt(selectedToken?.decimal));
        }
        return 0;
    };

    // Helper function to handle contract-related errors
    const handleContractError = (error) => {
        if (error.message?.includes("insufficient funds")) {
            toast.error('Insufficient funds. Please ensure your balance is sufficient to create this task.');
        } else if (error.message?.includes("revert")) {
            toast.error('Transaction reverted. Please check your input or contract settings.');
        } else {
            toast.error('Failed to create task on the blockchain. Please check allowance or try again.');
        }
        console.error('Allowance or contract call failed:', error);
    };

    // Helper function to save task data through API
    const saveTaskData = async (taskId, taskTransactionHash) => {
        const accessToken = localStorage.getItem('accessToken');
        const taskData = {
            title,
            description: value,
            reward: parseFloat(reward),
            categoryId: selectedCategory?.id,
            currency: selectedToken.currency,
            currencySymbol: selectedToken.currencySymbol,
            currencyAddress: selectedToken.currencyAddress,
            ...(contact && { contactInfo: contact }),
            endTime: new Date(endDate).toISOString(),
            contractTaskId: taskId,
            taskTransactionHash,
        };

        try {
            setLoader(true);
            const response = await fetch(`${API_URL}tasks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(taskData)
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Task created successfully:', result);
                clearForm();
                handleShow();
            } else {
                console.error('Failed to create task:', response.statusText);
                toast.error('Failed to create task in the system.');
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            toast.error('An error occurred while creating the task.');
        } finally {
            setLoader(false);
        }
    };

    // Helper function to clear the form
    const clearForm = () => {
        setTitle('');
        setValue('');
        setReward('');
        setEndDate('');
        setContact('');
        setSelectedCategory('');
        setSelectedToken({});
        setErrors({});
    
    };

    // const handleSubmit = async () => {
    //     console.log(2134124111111, 'rewardrewardreward');
    //    if(!account){
    //     toast.error('Please connect to your wallet')
    //     return
    //    }else{
    //     //    await web3.eth.requestAccounts();
    //    }
    //     console.log(21341241, 'rewardrewardreward');
    //     // Validate form data
    //     const validationErrors = validate();
    //     if (Object.keys(validationErrors).length > 0) {
    //         setErrors(validationErrors);
    //         return; // Stop form submission if there are errors
    //     }
         
    //     // Try to check allowance and make contract call
    //     let taskId ;
    //     let taskTransactionHash;
    //     try {
    //         setLoader(true);
    //         let balance;
    //         if (selectedToken?.decimal === 18 && selectedToken?.type === 'coin') {
    //             balance = await web3.eth.getBalance(account);
    //             balance = web3.utils.fromWei(balance); // For 18 decimal coin
    //         }
    //         else if (selectedToken?.decimal === 18) {
    //             balance = await BalanceOfHook(selectedToken.currencyAddress);
    //             balance = web3.utils.fromWei(balance); // For 18 decimal coin
    //         } else if (selectedToken?.decimal === 6) {
    //             balance = await BalanceOfHook(selectedToken.currencyAddress);
    //             balance = web3.utils.fromWei(balance.toString(), 'mwei'); // For 6 decimal tokens
    //         } else if (selectedToken?.decimal === 8) {
    //             balance = await BalanceOfHook(selectedToken.currencyAddress);
    //             balance = parseFloat(balance) / Math.pow(10, parseInt(selectedToken?.decimal)); // For 8 decimal tokens
    //         }
    //         console.log(balance, reward, 'rewardrewardreward');
            
    //         if (parseFloat(balance) < parseFloat(reward)){
    //             toast.error(`Balance must be greater than ${selectedToken.currencySymbol} reward amount`)
    //             setLoader(false)
    //             return;
    //         }
    //         console.log(balance, selectedToken.currencyAddress, 'vbalancebalancebalance');
            
    //         // First, check allowance
    //         if (selectedToken?.currencyAddress === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE') {
               
    //         }else{
    //             await AllowanceCore(selectedToken.currencyAddress, reward, selectedToken.decimal, selectedToken.currency); //allowance is only for type token not for coin like e
    //         }
            

    //         // If the allowance is sufficient, proceed with contract call
    //         const epochTime = Math.floor(new Date(endDate).getTime() / 1000); // Convert to seconds
    //         console.log(epochTime, endDate, 'epochTimeepochTime');
            

    //         // Call the contract function
    //        let res =  await createTast(reward, title, value, reward,  selectedToken.currencyAddress, selectedToken.decimal);
    //         console.log(res, 'resresresresres');
    //          taskId = res?.events?.TaskCreated?.returnValues?.taskId
    //         taskTransactionHash = res?.transactionHash
    //         console.log(taskId, 'resresresresres');
    //         setLoader(false);
    //         // toast.success('Contract call was successful!'); // Show success toast for contract

    //     } catch (error) {
    //         // Check if error is related to the contract
    //         setLoader(false);
    //         if (error.message && error.message.includes("insufficient funds")) {
    //             toast.error('Insufficient funds. Please ensure your balance is sufficient to create this task.');
    //         } else if (error.message && error.message.includes("revert")) {
    //             toast.error('Transaction reverted. Please check your input or contract settings.');
    //         } else {
    //             // General error message for other cases
    //             toast.error('Failed to create task on the blockchain. Please check allowance or try again.');
    //         }
    //         console.error('Allowance or contract call failed:', error);
    //         return; // Stop API call if AllowanceCore or contract call fails
    //     }

    //     // If contract call succeeds, proceed with API call
    //     const accessToken = localStorage.getItem('accessToken'); // Access token from local storage

    //     const taskData = {
    //         title,
    //         description: value,
    //         reward: parseFloat(reward),
    //         categoryId: selectedCategory?.id, 
    //         currency: selectedToken.currency,
    //         currencySymbol: selectedToken.currencySymbol,
    //         currencyAddress: selectedToken.currencyAddress,
    //         ...(contact && { contactInfo: contact }),
    //         endTime: new Date(endDate).toISOString(),
    //         contractTaskId: taskId,
    //         taskTransactionHash,
    //     };

    //     try {
    //         // Call the API to save task data
    //         setLoader(true);
    //         const response = await fetch(`${API_URL}tasks`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${accessToken}` // Include the access token
    //             },
    //             body: JSON.stringify(taskData)
    //         });

    //         if (response.ok) {
    //             const result = await response.json(); // Show success toast for API
    //             console.log('Task created successfully:', result);
    //             setLoader(false);
    //             // Clear all input fields on successful submission
    //             handleShow();
    //             setTitle('');
    //             setValue('');
    //             setReward('');
    //             setEndDate('');
    //             setContact('');
    //             setSelectedCategory('');
    //             setSelectedToken({});
    //             setErrors({}); // Clear errors on successful submission

    //         } else {
    //             // Show API error in toast
    //             setLoader(false);
    //             console.error('Failed to create task:', response.statusText);
    //             toast.error('Failed to create task in the system.');
    //         }

    //     } catch (error) {
    //         // Show error if the API call fails
    //         setLoader(false);
    //         console.error('Error occurred while creating task:', error);
    //         toast.error('An error occurred while creating the task.');
    //     }
    // };
    const fetchTasksCategories = async () => {
        try {
            const response = await fetch(`${API_URL}tasks-categories?limit=${50}`, {
                method: 'GET', // HTTP method
                headers: {
                    'Content-Type': 'application/json', // Set the content type if needed
                    // No authorization header needed since you're not using an access token
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setCategories(data?.data)
            // console.log(data?.data, 'datasdDS'); // Handle your data here
        } catch (error) {
            console.error('Error fetching tasks categories:', error);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0)
        fetchTasksCategories()
    }, [])
    useEffect(() => {
                window.scrollTo(0, 0)
              }, [])


    return (
        <>
         {loader && <Loader/>}
            <section className='create_task'>
                <img src='\assets\createtask\backkgroundimg.png' className='backimg' />
                <div className='custom-container'>
                    <Navbar />
                    <div className='outter_main'>
                        <h1 className="mb-3">Create Task</h1>
                        {/* <p className="loremmmm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}
                        <div className='inner_main'>
                            <h6>Task Title</h6>
                            <input
                                type="text"
                                placeholder='Enter task title here...'
                                value={title}
                                onChange={(e) => handleInputChange('title', e.target.value)}
                            />
                            {errors.title && <p className="error-text">{errors.title}</p>} {/* Error message */}

                            <h6>Description</h6>
                            <div className="text-editor-container myyyeditor">
                                <TextEditor value={value} setValue={(val) => handleInputChange('description', val)} />
                            </div>
                            {errors.description && <p className="error-text">{errors.description}</p>} {/* Error message */}

                            <h6>Category</h6>
                            <Dropdown className="categories-dropdownlist">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {selectedCategory?.categoryName || 'Select task category...'}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M13.3333 8.33337L9.99996 11.6667L6.66663 8.33337" stroke="#013159" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {categories.map((category, index) => (
                                        <Dropdown.Item
                                            key={index}
                                            onClick={() => handleCategorySelect(category)}
                                        >
                                            {category?.categoryName}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                            {errors.category && <p className="error-text">{errors.category}</p>} {/* Error message */}

                            <div className="innner_center">
                                <div className="secondrop">
                                    <h6>Reward Currency</h6>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {selectedToken.currencySymbol || 'Select reward currency'}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M13.3333 8.33337L9.99996 11.6667L6.66663 8.33337" stroke="#013159" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {tokens.map((token, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleTokenSelect(token)}
                                                >
                                                    <img src={token.imgSrc} alt={token.currency} style={{ marginRight: '8px' }} />
                                                    {token.currencySymbol}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {errors.currency && <p className="error-text">{errors.currency}</p>} {/* Error message */}
                                </div>
                                <div className="thrddrop">
                                    <h6>Reward Amount</h6>
                                    <input
                                        type="number"
                                        placeholder='Enter task reward...'
                                        value={reward}
                                        onChange={(e) => handleInputChange('reward', e.target.value)}
                                    />
                                    {errors.reward && <p className="error-text">{errors.reward}</p>} {/* Error message */}
                                </div>
                            </div>
                            <div className="thrddrop">
                                    <h6>My Contact</h6>
                                    <input
                                        type="text"
                                        placeholder='Enter Contact Info...'
                                        value={contact}
                                        onChange={(e) => setContact( e.target.value)}
                                    />
                                    
                                </div>
                          
                           <h6>Task End Date</h6>
                           <div className="typedate-field">
                           <label
                                htmlFor="endDate"
                                className={endDate ? "d-none" : "showinputtypedate-text d-none"}
                                style={{
                                    position: "absolute",
                                    top: endDate ? "" : "20px", 
                                    left: "15px",
                                    fontSize: endDate ? "12px" : "16px",
                                    color: "#627F97",
                                    pointerEvents: "none",
                                    transition: "0.2s ease",
                                }}
                            >
                                DD/HH/MM/SS
                            </label>
                                <input
                                    type="datetime-local"
                                    value={endDate}
                                    onChange={(e) => handleInputChange('endDate', e.target.value)}
                                    min={new Date().toISOString().slice(0, -8)} // Set minimum date-time to current
                                />
                            <img src="\assets\calendar-icon.svg" alt="img" className="img-fluid" />
                            </div>
                            {errors.endDate && <p className="error-text">{errors.endDate}</p>} {/* Error message */}
                     

                        </div>
                    
                        <div className="d-flex w-100">
                            <button className="btn-createtask w-50 mx-1" onClick={clearForm}>Clear Form</button>
                            <button className="btn-createtask w-50 mx-1" onClick={handleSubmit}>Create Task</button>
                        </div>
                       
                    </div>
                </div>
            </section>
            <Modal show={show} onHide={handleClose} className="modal_main2" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <img className="imgqus" src="\assets\createtask\tick.png" />
                        <h1>Task Successfully Created</h1>
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        
                        <Link to="/latesttask" className="surebutton text-center" >OK</Link>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Createtask;



// import React, { useEffect, useState } from "react";
// import Dropdown from 'react-bootstrap/Dropdown';
// import Form from 'react-bootstrap/Form';
// import "./createtask.scss";
// import Modal from 'react-bootstrap/Modal';
// import Navbar from "../landing/header/Navbar";
// import { Editor } from 'react-draft-wysiwyg';
// import { convertToRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import TextEditor from "./TextEditor";
// const Createtask = () => {
//     const [value, setValue] = useState("");
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//     const [editorState, setEditorState] = useState('');
//     const [description, setDescription] = useState('');
// const [categories, setCategories] = useState(['Music', 'Marketing', 'Design', 'Media', 'Crypto', 'Gaming']);
//     const [tokens] = useState([
//         {
//             currency: "ETH",
//             currencySymbol: "ETH",
//             currencyAddress: "0x0000000000000000000000000000000000000000",
//             imgSrc: "/assets/createtask/eth.svg"
//         },
//         {
//             currency: "USDT",
//             currencySymbol: "USDT",
//             currencyAddress: "0x0000000000000000000000000000000000000001",
//             imgSrc: "/assets/createtask/usdtT.svg"
//         },
//         {
//             currency: "USDC",
//             currencySymbol: "USDC",
//             currencyAddress: "0x0000000000000000000000000000000000000002",
//             imgSrc: "/assets/createtask/usdc.svg"
//         },
//         {
//             currency: "LINK",
//             currencySymbol: "LINK",
//             currencyAddress: "0x0000000000000000000000000000000000000003",
//             imgSrc: "/assets/createtask/link.svg"
//         },
//         {
//             currency: "PEPE",
//             currencySymbol: "PEPE",
//             currencyAddress: "0x0000000000000000000000000000000000000004",
//             imgSrc: "/assets/createtask/pepe.svg"
//         },
//         {
//             currency: "UNI",
//             currencySymbol: "UNI",
//             currencyAddress: "0x0000000000000000000000000000000000000005",
//             imgSrc: "/assets/createtask/uni.svg"
//         },
//         {
//             currency: "DOP",
//             currencySymbol: "DOP",
//             currencyAddress: "0x0000000000000000000000000000000000000006",
//             imgSrc: "/assets/createtask/dop1.svg"
//         },
//         {
//             currency: "WBTC",
//             currencySymbol: "WBTC",
//             currencyAddress: "0x0000000000000000000000000000000000000007",
//             imgSrc: "/assets/createtask/wbtc.svg"
//         }
//     ]);
//     const handleEditorChange = (value) => {
//         setEditorState(value);
//     };

//     const handleDescriptionChange = (e) => {
//         setDescription(e.target.value);
//     };

//     useEffect(() => {
//         window.scrollTo(0, 0)
//       }, [])


//     return (
//         <>
//             <section className='create_task'>
//                 <img src='\assets\createtask\backkgroundimg.png' className='backimg' />
//                 <div className='custom-container'>
//                     <Navbar />
//                     <div className='outter_main'>
//                         <h1>Create Task</h1>
//                         <p className="loremmmm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
//                         <div className='inner_main'>
//                             <h6>Task Title</h6>
//                             <input type="text" id="fname" name="fname" placeholder='Enter task title here...' />
//                             <h6>description</h6>
//                             <div className="text-editor-container myyyeditor">
                               
//                                 <TextEditor value={value} setValue={setValue}/>
                         
//                             </div>
//                             <h6>category</h6>
//                             <Dropdown>
//                                 <Dropdown.Toggle variant="success" id="dropdown-basic">
//                                     Select task category here...<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                         <path d="M13.3333 8.33337L9.99996 11.6667L6.66663 8.33337" stroke="#013159" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
//                                     </svg>
//                                 </Dropdown.Toggle>

//                                 <Dropdown.Menu>
//                                     {categories?.map((category)=>
//                                         <Dropdown.Item href="#/action-1">{category}</Dropdown.Item>
//                                     )}
//                                 </Dropdown.Menu>
//                             </Dropdown>
//                             <div className="innner_center">
//                                 <div className="secondrop">
//                                     <h6>Reward Currency</h6>
//                                     <Dropdown>
//                                         <Dropdown.Toggle variant="success" id="dropdown-basic">
//                                             Select reward currency <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
//                                                 <path d="M13.3333 8.33337L9.99996 11.6667L6.66663 8.33337" stroke="#013159" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
//                                             </svg>
//                                         </Dropdown.Toggle>

//                                         <Dropdown.Menu>
//                                             {tokens?.map((token, index) => (
//                                                 <Dropdown.Item key={index} href={`#/action-${index + 1}`}>
//                                                     <img src={token.imgSrc} alt={token.currency} style={{ marginRight: '8px' }} />
//                                                     {token.currencySymbol}
//                                                 </Dropdown.Item>
//                                             ))}
//                                         </Dropdown.Menu>
//                                     </Dropdown>
//                                 </div>
//                                 <div className="thrddrop">
//                                     <h6>Reward Amount</h6>
//                                     <input type="text" id="fname" name="fname" placeholder='Enter task reward here...' />
//                                 </div>

//                             </div>
//                             <h6>Task End Date</h6>
//                             <input type="date" />
                           
//                         </div>
//                         <button className="btn-createtask" >CREATE TASK</button>
//                     </div>
//                 </div>

//             </section>

            
//         </>
//     )
// }

// export default Createtask