import '../../app.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar.js';
import About from './About/About.js';
import LatestTask from './LatestTask/LatestTask.js';
import How from './How/How.js';
import What from './Whatwedo/What.js';
import Join from './Join/Join.js';


function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <LatestTask />
      <How />
      <What />
      <Join />
    </>
  );
}

export default Landing;