import React, { useEffect, useState } from 'react'
import './similartaskdetail.scss'
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Navbar from '../landing/header/Navbar';
import { Link, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { API_URL } from '../../utils/ApiUrl';
import { useWeb3React } from "@web3-react/core";
import Timer from '../landing/LatestTask/Timer';
import TaskTimer from './TaskTimer';
import SimilarTasks from './SimilarTasks';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import Loader from '../../hooks/loader';
import TaskTimer1 from './TaskTimer1';

const Similartaskdetail = () => {
    const [loader, setLoader] = useState(false);
    const { account } = useWeb3React();
    const [userId, setUserId] = useState(0);
    const [isCopied, setIsCopied] = useState(false);
    const [uniqueCode, setUniqueCode] = useState(null);
    const [uniqueStatus, setUniqueStatus] = useState(null);
    const { id } = useParams(); // Get the task ID from the URL
    const [taskData, setTaskData] = useState(null); // State for storing fetched task data
    const [show, setShow] = useState(false);
    const [bool, setBoolean] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [taskIds, setTaskIds] = useState()
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);
    const [message, setMessage] = useState('');
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState('');
    const [apiReload, setApiReload] = useState(false)

    const [disputeChec, setDisputeCheck] = useState(false)
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);
    const tokenImages = {
        ETH: "/assets/createtask/eth.svg",
        USDT: "/assets/createtask/usdtT.svg",
        USDC: "/assets/createtask/usdc.svg",
        LINK: "/assets/createtask/link.svg",
        PEPE: "/assets/createtask/pepe.svg",
        UNI: "/assets/createtask/uni.svg",
        DOP: "/assets/createtask/dop1.svg",
        WBTC: "/assets/createtask/wbtc.svg"
    };
    const onClickCopy = async (txt) => {
        try {
            copy(txt);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    async function participate() {
        handleClose();
        setLoader(true);
        try {
            // Get the accessToken from localStorage
            const accessToken = localStorage.getItem('accessToken');

            // Set up the config with headers
            const config = {
                headers: {
                    Authorization: `Bearer ${accessToken}` // Add the token to the Authorization header
                }
            };

            // Prepare the data you want to send in the POST request (optional)
            const data = {
                taskId: taskIds
            };

            // Make the POST request
            const response = await axios.post(`${API_URL}participants`, data, config);
            // console.log(response?.data?.data, 'response?.data');
            setUniqueCode(response?.data?.data?.uniqueCode)
            setUniqueStatus(response?.data?.data?.status)
            setLoader(false);
            handleShow1();
            // Handle the response (success)
            // console.log('Participation successful:', response.data);
        } catch (error) {
            // Handle any errors
            setLoader(false);
            toast.error("Something went wrong");
            console.error('Error participating in task:', error);
        }
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileType = selectedFile.type;
            // Ensure the file type is valid
            if (
                ['application/pdf', 'text/plain', 'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'image/jpeg', 'image/png', 'image/gif'].includes(fileType)
            ) {
                setFile(selectedFile);
                setFilePreview(URL.createObjectURL(selectedFile)); // Create a URL for the file preview
            } else {
                toast.error('Unsupported file type. Please upload a PDF, TEXT, DOC, or DOCX file.');
            }
        }
    };

    const handleSubmit = async () => {
        if (!file || !message) {
            toast.warn('Please fill in the message and select a file.');
            return;
        }
        let accessToken = localStorage.getItem('accessToken');
        setLoader(true);
        // Step 1: Upload the file to get the URL or an error
        let uploadedImageResponse = await imgeUrl(file); // Call the function to upload the image and get the URL or error

        // Step 2: Check if the response contains an error
        if (uploadedImageResponse && uploadedImageResponse.error) {
            toast.error(`Failed to upload the file: ${uploadedImageResponse.error}`);
            setLoader(false);
            return;
        }

        // If no error, get the uploaded image URL
        let uploadedImageUrl = uploadedImageResponse;

        // Step 3: Prepare data for the PATCH request
        const dataToSubmit = {
            taskId: id,
            message: message,
            uniqueCode: uniqueCode, // Replace with the actual unique code if it's dynamic
            proofOfWork: [uploadedImageUrl], // Add the uploaded image URL to this array
        };

        try {
            // Replace 'your-api-endpoint' with the actual endpoint
            await axios.patch(`${API_URL}participants`, dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json', // Use application/json since we are sending a JSON object
                    'Authorization': `Bearer ${accessToken}`
                },
            });
            setBoolean('adfasf');
            handleClose2(); // Close modal after successful submission
            handleShow3();
            setLoader(false);
        } catch (error) {
            // Log detailed error response if available
            if (error.response) {
                console.error('API Error:', error.response.data.message || error.response.data);
                setLoader(false);
                toast.error(`Error submitting task: ${error.response.data.message || 'Unknown error'}`);
            } else {
                console.error('Error submitting the task:', error);
                setLoader(false);
                toast.error('Failed to submit the task. Please try again.');
            }
        }
    };



    const handleSubmitDispute = async () => {
        if (!file || !message) {
            toast.warn('Please fill in the message and select a file.');
            return;
        }
        let accessToken = localStorage.getItem('accessToken');
        setLoader(true);
        // Step 1: Upload the file to get the URL or an error
        let uploadedImageResponse = await imgeUrl(file); // Call the function to upload the image and get the URL or error

        // Step 2: Check if the response contains an error
        if (uploadedImageResponse && uploadedImageResponse.error) {
            toast.error(`Failed to upload the file: ${uploadedImageResponse.error}`);
            setLoader(false);
            return;
        }

        // If no error, get the uploaded image URL
        let uploadedImageUrl = uploadedImageResponse;

        // Step 3: Prepare data for the PATCH request
        const dataToSubmit = {
            message: message, // Replace with the actual unique code if it's dynamic
            proofOfWork: [uploadedImageUrl], // Add the uploaded image URL to this array
        };

        try {
            // Replace 'your-api-endpoint' with the actual endpoint
            await axios.post(`${API_URL}tasks/${id}/dispute`, dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json', // Use application/json since we are sending a JSON object
                    'Authorization': `Bearer ${accessToken}`
                },
            });
            setBoolean('adfasf');
            // toast.success('File submitted successfully!');
            handleClose2(); // Close modal after successful submission
            handleShow5()
            setLoader(false);
            setApiReload(true)
        } catch (error) {
            // Log detailed error response if available
            if (error.response) {
                console.error('API Error:', error.response.data.message || error.response.data);
                setLoader(false);
                toast.error(`Error submitting task: ${error.response.data.message || 'Unknown error'}`);
            } else {
                console.error('Error submitting the task:', error);
                setLoader(false);
                toast.error('Failed to submit the task. Please try again.');
            }
        }
    };


    // Updated imgeUrl function to return the URL
    const imgeUrl = async (e) => {
        // console.log(e, 'e');
        let accessToken = localStorage.getItem('accessToken');

        // Create a FormData object to send the image
        let formData = new FormData();
        formData.append('image', e);  // Assuming 'e' is the image file

        const config = {
            method: "post",
            url: `${API_URL}metadata/upload-image`,
            headers: {
                'Content-Type': 'multipart/form-data',  // Correct content type for file uploads
                'Authorization': `Bearer ${accessToken}`
            },
            data: formData,  // Send formData with the image file
        };

        try {
            let response = await axios(config);
            if (response) {
                // console.log(response.data.url, 'response');
                return response.data.url; // Return the URL for further processing
            }
        } catch (error) {
            // Extract the error message from the API response and return it
            if (error.response) {
                const apiErrorMessage = error.response.data.message || error.response.data;
                console.error('API Error:', apiErrorMessage);
                return { error: apiErrorMessage }; // Return an object containing the error message
            } else {
                console.error('Error occurred while uploading image:', error);
                return { error: 'An unknown error occurred while uploading the image.' }; // Return a default error message
            }
        }
    };


    // Fetch data when component mounts
    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                // Get the accessToken from localStorage
                const accessToken = localStorage.getItem('accessToken');
                // Set up the config with headers
                // Set up the config with headers conditionally
                const config = accessToken
                    ? { headers: { Authorization: `Bearer ${accessToken}` } }
                    : {}; // If no token, leave headers empty
                // Make the API request with the Authorization header
                const response = await axios.get(`${API_URL}tasks/${id}`, config);
                // Store the fetched data in the state
                setTaskData(response?.data?.data);
            } catch (error) {
                // toast.error("Please connect your wallet!");
                console.error("Error fetching task data:", error);
            }
        };

        fetchTaskData(); // Call the function
        window.scrollTo(0, 0); // Scroll to top
    }, [id, uniqueCode, apiReload]);
    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                // Get the accessToken from localStorage
                const accessToken = localStorage.getItem('accessToken');

                // Set up the config with headers
                // Set up the config with headers conditionally
                const config = accessToken
                    ? { headers: { Authorization: `Bearer ${accessToken}` } }
                    : {}; // If no token, leave headers empty

                // Make the API request with the Authorization header
                const secretCodeResponse = await axios.get(`${API_URL}participants/unique-code/${id}`, config);
                // console.log(secretCodeResponse, 'secretCodeResponse');
                setUniqueStatus(secretCodeResponse?.data?.data?.status)
                // Store the fetched data in the state
                setUniqueCode(secretCodeResponse?.data?.data?.uniqueCode);
            } catch (error) {
                console.error("Error fetching task data:", error);
            }
        };
        let userId = localStorage.getItem('userId');
        setUserId(userId)
        fetchTaskData(); // Call the function
        window.scrollTo(0, 0); // Scroll to top
    }, [id, bool]);
    // console.log(uniqueStatus, uniqueStatus, 'uniqueStatus');

    const errorMsg = () => {
        toast.error("Connect Wallet!")
    };
    return (
        <>
            {loader && <Loader />}
            <Navbar />
            <section className="similartaskdetail">
                <img src="/assets/importantassets/backshade.png" alt="backshade" className="backshade" />
                <img src="/assets/importantassets/backshadembl.png" alt="backshade" className="backshadembl d-none" />
                <div className="custom-container">
                    <p className="toppara">
                        <span className="light">Home </span>
                        / {taskData?.tasksCategory?.categoryName}
                    </p>
                    <div className="detailmain">
                        <div className="detailsleft">
                            {/* <span className="statusmain">
                                <span className="statuscircle"></span>
                                <p className="statuspara">PARTICIPATED</p>
                            </span> */}
                            <h4 className="detailhead">{taskData?.title}</h4>
                            {/* <p className="detailpara">{taskData?.description}</p> */}
                            <div
                                className="detailpara"
                                // Uncomment the next line if using DOMPurify
                                // dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                                dangerouslySetInnerHTML={{ __html: taskData?.description }}
                            />
                        </div>
                        <div className="detailsright">
                            <div className="innerdetailsright">
                                <div className="detailtop">
                                    <div className="detailinnerleft">
                                        <p className="rewardpara">Reward</p>
                                        <div className="rewardamount">
                                            <div className="rewardimg">
                                                <img src={tokenImages[taskData?.currencySymbol]} alt="innerrewardimg" className="innerrewardimg" />
                                            </div>
                                            {taskData?.reward?.toLocaleString()} {taskData?.currencySymbol}
                                        </div>
                                    </div>
                                    <div className="detailinnerright">
                                        <p className="participantpara">Participants</p>
                                        <div className="partcipantmainhead">
                                            {
                                                taskData?.participants?.slice(0, 4).map((e, i) => {
                                                    let classn
                                                    if (i == 0) {
                                                        classn = 'one'
                                                    } else if ((i == 1)) {
                                                        classn = 'two'
                                                    } else if ((i == 2)) {
                                                        classn = 'three'
                                                    } else {
                                                        classn = 'four'
                                                    }
                                                    // if(i==3){
                                                    //     return
                                                    // }
                                                    return (
                                                        <div className={`participantmain ${classn}`}>
                                                            <img src={e?.user?.imageUrl
                                                                ? (e.user.imageUrl.startsWith('http')
                                                                    ? e.user.imageUrl
                                                                    : `https://${e.user.imageUrl}`)
                                                                : '/assets/edituploadimg.png'} alt="participantimg" className="participantimg" />                                                        </div>
                                                    )
                                                })}
                                            {/* <div className="participantmain one">
                                                <img src="/assets/dummyassets/dummyone.png" alt="participantimg" className="participantimg" />
                                            </div>
                                            <div className="participantmain two">
                                                <img src="/assets/dummyassets/dummytwo.png" alt="participantimg" className="participantimg" />
                                            </div>
                                            <div className="participantmain three">
                                                <img src="/assets/dummyassets/dummythree.png" alt="participantimg" className="participantimg" />
                                            </div>
                                            <div className="participantmain four">
                                                <img src="/assets/dummyassets/dummyfour.png" alt="participantimg" className="participantimg" />
                                            </div> */}
                                            {/* <div className="participantmain five"> */}
                                            {taskData?.participantCoun > 4 && <div className="participantmain five">
                                                <p className="numberimages">
                                                    {parseInt(taskData?.participantCount) - 4}
                                                </p>
                                            </div>}
                                            {taskData?.participantCount === '0' && <div className="participantmain ">
                                                <p className="numberimages">
                                                    0
                                                </p>
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="detailinnerright">
                                        <p className="participantpara">Created By</p>
                                        <div className="creatormain">
                                            <div className={taskData?.user?.imageUrl ? "creatormainimg" : 'd-none'}>
                                                {taskData?.user?.imageUrl ? <img src={taskData?.user?.imageUrl.startsWith('http') ? taskData.user.imageUrl : `https://${taskData.user.imageUrl}`} alt="img" className='creatorimg' /> : null}
                                            </div>
                                            <p className="creatorwallet">{taskData?.user?.name || taskData?.user?.walletAddress?.slice(0, 5) + '...' + taskData?.user?.walletAddress?.slice(-5)}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="maintimer">
                                    <p className="endpara">Ends in</p>
                                    {taskData?.participants[0]?.status === 'winner' ?
                                        <TaskTimer endTime={0} />
                                        :
                                        <TaskTimer endTime={taskData?.endTime} />


                                    }

                                </div>
                                {/* {(userId == taskData?.userId) ? null : uniqueCode ? null : <button onClick={() => { setTaskIds(taskData?.id);handleShow()}} className="participatebtn">
                                    Participate
                                </button>} */}
                                {taskData?.participants[0]?.status === 'winner' ?
                                    ""
                                    :
                                    <>
                                        {uniqueCode ? uniqueStatus === 'submitted' ? null : new Date(taskData?.endTime) > new Date() ? <button onClick={() => { setTaskIds(taskData?.id); handleShow2() }} className="participatebtn">
                                            Submit task
                                        </button> : null : null}</>
                                }
                                {taskData?.participants[0]?.status === 'winner' ?
                                    ""
                                    :
                                    <>
                                        {(userId == taskData?.userId) ? null : uniqueCode ? null : (new Date(taskData?.endTime) < new Date()) ? null : account ?
                                            <button onClick={() => { setTaskIds(taskData?.id); handleShow() }} className="participatebtn">Participate</button>
                                            : <button onClick={errorMsg} className="participatebtn">Participate</button>

                                        }</>
                                }
                                {uniqueCode ? <p className="uniquepara">
                                    Unique Secret Code: <span className="code">{uniqueCode}</span>
                                </p> : null}
                                <div className="text-center mt-4">
                                    <a target="_blank" href={`https://etherscan.io/tx/${taskData?.taskTransactionHash}`}>      <img src="/assets/latesttask/explorer.svg" alt="" srcset="" /></a>
                                </div>

                            </div>
                            {taskData?.participants[0]?.status === 'winner' || (new Date(taskData?.endTime) <= new Date()) ?
                                <div className="innerdetailsright">
                                    <h6 className="reviewhead">Review</h6>
                                    <p className="reviewpara">Remaining time for the task creator to review the proof of work and decide on the winner for this task.</p>
                                    <div className="maintimer">
                                        {taskData?.participants[0]?.status === 'winner' ?
                                            <TaskTimer endTime={0} />
                                            :
                                            <TaskTimer endTime={taskData?.reviewTimePeriod} />
                                        }
                                    </div>
                                    {
                                        taskData?.participants[0]?.status === 'winner' &&
                                        <div className="winnermain">
                                            <p className="winnerpara">
                                                Winner:
                                            </p>

                                            <div className="winnermainhead">
                                                <div className="winnermainimg">
                                                    <img src={taskData?.participants[0]?.user?.imageUrl ? taskData?.participants[0]?.user?.imageUrl : "/assets/edituploadimg.png"} alt="winnerimg" className="winnerimg" />
                                                </div>
                                                <p className="winnerwallet">{taskData?.participants[0]?.user?.walletAddress?.slice(0, 5)}...{taskData?.participants[0]?.user?.walletAddress?.slice(-3)}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                                :
                                ""

                            }
                            {(new Date(taskData?.reviewTimePeriod) < new Date()) || taskData?.participants[0]?.status === 'winner' ?
                                <>
                                    <div className="innerdetailsright">
                                        <h6 className="reviewhead">Dispute Period</h6>
                                        {/* <p className="reviewpara">Remaining time for the task creator to review the proof of work and decide on the winner for this task.</p> */}
                                        <div className="maintimer">

                                            <TaskTimer endTime={taskData?.coolDownPeriod} />
                                            {/* <TaskTimer endTime={taskData?.endTime} /> */}
                                        </div>
                                        {userId == taskData?.userId || !taskData?.hasParticipated || taskData?.participants[0]?.user?.walletAddres?.toLocaleLowerCase() === account?.toLocaleLowerCase() ?
                                            ""
                                            :
                                            <>
                                                {!taskData?.hasDisputed ?
                                                    <button
                                                        onClick={() => {
                                                            setTaskIds(taskData?.id);
                                                            setDisputeCheck(true)
                                                            handleShow2()
                                                        }}
                                                        className="participatebtn">Create Dispute</button>
                                                    :
                                                    <button className="participatebtn">Dispute Created</button>
                                                }
                                            </>

                                        }

                                    </div></>
                                :

                                ""
                            }





                        </div>
                    </div>
                    <div className="similartop">
                        <h4 className="similarhead">
                            Similar Tasks
                        </h4>
                        <Link to="/latesttask">
                            <button className="similarbtn">
                                View All Tasks
                            </button></Link>

                    </div>

                </div>
                <SimilarTasks taskId={id} categoryId={taskData?.categoryId} handleShow={handleShow} />
            </section>


            <Modal show={show} onHide={handleClose} className="modal_main dcscysysbc" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Participate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal1">
                        <img className="imgqus" src="\assets\createtask\qusmark.png" />
                        <h1>Are you sure you want to participate <br /> in this task?</h1>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose}>Cancel</button>
                        <button onClick={() => {

                            participate()
                        }} className="surebutton">Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={show1} onHide={handleClose1} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal2">
                        <img className="imgqus" src="\assets\createtask\tick.png" />
                        <h1>Your participation has been registered.</h1>
                        <div className="codediv">
                            <p>Unique Secret Code:</p>
                            <h2>{uniqueCode} {isCopied ? ' Copied' : <svg onClick={() => onClickCopy(uniqueCode)} xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" style={{ cursor: 'pointer' }}>
                                <path d="M17.375 2.65625H7.375C7.25068 2.65625 7.13145 2.70564 7.04354 2.79354C6.95564 2.88145 6.90625 3.00068 6.90625 3.125V6.40625H3.625C3.50068 6.40625 3.38145 6.45564 3.29354 6.54354C3.20564 6.63145 3.15625 6.75068 3.15625 6.875V16.875C3.15625 16.9993 3.20564 17.1185 3.29354 17.2065C3.38145 17.2944 3.50068 17.3438 3.625 17.3438H13.625C13.7493 17.3438 13.8685 17.2944 13.9565 17.2065C14.0444 17.1185 14.0938 16.9993 14.0938 16.875V13.5938H17.375C17.4993 13.5938 17.6185 13.5444 17.7065 13.4565C17.7944 13.3685 17.8438 13.2493 17.8438 13.125V3.125C17.8438 3.00068 17.7944 2.88145 17.7065 2.79354C17.6185 2.70564 17.4993 2.65625 17.375 2.65625ZM13.1562 16.4062H4.09375V7.34375H13.1562V16.4062ZM16.9062 12.6562H14.0938V6.875C14.0938 6.75068 14.0444 6.63145 13.9565 6.54354C13.8685 6.45564 13.7493 6.40625 13.625 6.40625H7.84375V3.59375H16.9062V12.6562Z" fill="#013159" />
                            </svg>}</h2>
                        </div>

                        <h3>Please include this code in your proof of work. Verification of successful completion will not be possible without it.</h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose1}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Proof</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className='dont-foreget-text'>Don't forget to provide the unique secret code you received when you accepted this task, A successful completion of the task cannot be verified without this code.</h6>
                    <div className="modal3">
                        <h1>Message</h1>
                        <Form.Control
                            as="textarea"
                            placeholder="Enter your message here..."
                            style={{ height: '100px' }}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <h1>Upload File</h1>
                        <div className="modal3_inner">
                            <h2>{filePreview ? 'Selected File' : 'Select File here'}</h2>
                            <p style={{ fontSize: "12px" }}>{filePreview ? <a href={filePreview} target="_blank" rel="noopener noreferrer">
                                {file.name}
                            </a> : 'Files Supported : PDF, TEXT, DOC, DOCX, PNG, JPEG'}</p>
                            <input
                                type="file"
                                accept=".pdf,.txt,.doc,.docx,image/*" // Allow images as well
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="file-upload"
                            />
                            <label htmlFor="file-upload" className="ChooseFile cppp">Select File</label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose2}>CANCEL</button>
                        <button className="surebutton" onClick={() => {
                            if (disputeChec) {
                                handleSubmitDispute()
                            } else {
                                handleSubmit()
                            }

                        }}>SUBMIT</button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* <Modal show={show2} onHide={handleClose2} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Proof</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal3">
                        <h1>Upload File</h1>
                        <div className="modal3_inner">
                            <h2>Select File here</h2>
                            <p>Files Supported : PDF, TEXT, DOC, DOCX</p>
                            <button className="ChooseFile">Choose File</button>
                        </div>
                        <h1>Message</h1>

                        <Form.Control
                        onClick={(e)=>{setMessage(e.target.value)}}
                            as="textarea"
                            placeholder="Enter your message here..."
                            style={{ height: '100px' }}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose2}>CANCEL</button>
                        <button className="surebutton">SUBMIT</button>
                    </div>
                </Modal.Footer>
            </Modal> */}

            <Modal show={show3} onHide={handleClose3} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <img className="imgqus" src="\assets\createtask\tick.png" />
                        <h1>Task Submitted!</h1>
                        <p>The proof of work you provided will be reviewed by the community. Once successful completion is verified by the community, you will be able to claim your reward.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose3}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4} className="modal_mainrr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Dispute</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalfst">
                        <h1>Message</h1>

                        <Form.Control
                            as="textarea"
                            placeholder="Enter your message here..."
                            style={{ height: '100px' }}
                        />
                        <h1>Upload File</h1>
                        <div className="modal3_inner">
                            <h2>Select File here</h2>
                            <p className="parssa">Files Supported : PDF, TEXT, DOC, DOCX</p>
                            <button className="ChooseFile">Choose File</button>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose}>CANCEL</button>
                        <button className="surebutton">SUBMIT</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show5} onHide={handleClose5} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="28.2036" y="49.8564" width="2" height="40.2235" transform="rotate(-135 28.2036 49.8564)" fill="#013159" />
                            <rect x="17" y="38.625" width="2" height="15.43" transform="rotate(-45 17 38.625)" fill="#013159" />
                        </svg>
                        <h1>Dispute created successfully</h1>
                        <p className="parssa">The DAO will review your request.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose5}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show6} onHide={handleClose6} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Withdraw</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="34" y="18" width="2" height="28" fill="#013159" />
                            <rect x="34" y="49" width="2" height="3" fill="#013159" />
                        </svg>
                        <h1>Are you sure you want to claim
                            100 TOMI for this task?</h1>
                        {/* <p className="parssa">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose}>CANCEL</button>
                        <button className="surebutton" onClick={handleClose4}>Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default Similartaskdetail
