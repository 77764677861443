import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { taskManagerContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const TaskCheck = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tastManager;
  const contract = taskManagerContract(tokenAddress, web3);


  const taskCheck = useCallback(
    async (v) => {
 
      try {
        const buy = await contract.methods.tasks(v).call()
        
        return buy;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { taskCheck: taskCheck };
};
export default TaskCheck;