import React from 'react'
import "./join.scss"
import { Link } from 'react-router-dom'

const Join = () => {
    return (
        <>
            <section className="join-section" id='join'>
                <img src="\assets\join\join-bg.png" alt="img" className='img-fluid join-bg' />
                <div className="custom-container">
                    <div className="main-content">
                        <h4>Join the Future of Decentralized Work</h4>
                        <p>Whether you’re looking to create tasks or complete them, Tasks provides a secure, fair, and transparent platform for rewarding work. Start exploring tasks or post your own today!</p>
                        <div className="twice-btns">
                            <Link to="/createtask" className="btn-create">Create Task</Link>
                            <Link to="/latesttask" className="btn-explore">Explore Tasks</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Join
