import React, { useState } from "react";
import "./wardah.scss";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
const Wardah = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);



    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
    return (
        <>
            <div>
                <h1 onClick={handleShow}>Modal1</h1>
                <h1 onClick={handleShow1}>Modal2</h1>
                <h1 onClick={handleShow2}>Modal3</h1>
                <h1 onClick={handleShow3}>Modal4</h1>
            </div>










            <Modal show={show} onHide={handleClose} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Participate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal1">
                        <img className="imgqus" src="\assets\createtask\qusmark.png" />
                        <h1>Are you sure you want to participate in this task?</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose}>CANCEL</button>
                        <button className="surebutton">YES I’M SURE</button>
                    </div>
                </Modal.Footer>
            </Modal>


            <Modal show={show1} onHide={handleClose1} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal2">
                        <img className="imgqus" src="\assets\createtask\tick.png" />
                        <h1>You have participated in the task!</h1>
                        <div className="codediv">
                            <p>Your Unique Code Is:</p>
                            <h2>Qwerty12345@/- <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                <path d="M17.375 2.65625H7.375C7.25068 2.65625 7.13145 2.70564 7.04354 2.79354C6.95564 2.88145 6.90625 3.00068 6.90625 3.125V6.40625H3.625C3.50068 6.40625 3.38145 6.45564 3.29354 6.54354C3.20564 6.63145 3.15625 6.75068 3.15625 6.875V16.875C3.15625 16.9993 3.20564 17.1185 3.29354 17.2065C3.38145 17.2944 3.50068 17.3438 3.625 17.3438H13.625C13.7493 17.3438 13.8685 17.2944 13.9565 17.2065C14.0444 17.1185 14.0938 16.9993 14.0938 16.875V13.5938H17.375C17.4993 13.5938 17.6185 13.5444 17.7065 13.4565C17.7944 13.3685 17.8438 13.2493 17.8438 13.125V3.125C17.8438 3.00068 17.7944 2.88145 17.7065 2.79354C17.6185 2.70564 17.4993 2.65625 17.375 2.65625ZM13.1562 16.4062H4.09375V7.34375H13.1562V16.4062ZM16.9062 12.6562H14.0938V6.875C14.0938 6.75068 14.0444 6.63145 13.9565 6.54354C13.8685 6.45564 13.7493 6.40625 13.625 6.40625H7.84375V3.59375H16.9062V12.6562Z" fill="#013159" />
                            </svg></h2>
                        </div>

                        <h3>Please include your unique code in your proof of work.</h3>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose1}>Okay</button>
                    </div>
                </Modal.Footer>
            </Modal>



            <Modal show={show2} onHide={handleClose2} className="modal_main" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Submit Proof</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal3">
                        <h1>Upload File</h1>
                        <div className="modal3_inner">
                            <h2>Select File here</h2>
                            <p>Files Supported : PDF, TEXT, DOC, DOCX</p>
                            <button className="ChooseFile">Choose File</button>
                        </div>
                        <h1>Message</h1>

                        <Form.Control
                            as="textarea"
                            placeholder="Enter your message here..."
                            style={{ height: '100px' }}
                        />

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose2}>CANCEL</button>
                        <button className="surebutton">SUBMIT</button>
                    </div>
                </Modal.Footer>
            </Modal>





            <Modal show={show3} onHide={handleClose3} className="modal_main" centered>
            <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                    <img className="imgqus" src="\assets\createtask\tick.png" />
                        <h1>Task Submitted!</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose3}>Okay</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>

    )
}

export default Wardah