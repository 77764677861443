import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { taskManagerContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const RefundTask = () => {

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tastManager;
  const contract = taskManagerContract(tokenAddress, web3);


  const refundTask = useCallback(
    async (taskId,sign) => {
      try {
        const gas = await contract.methods.refundTask(taskId,sign).estimateGas({
            from:account });
        const details = await contract.methods.refundTask(taskId,sign).send({
          from: account,
          gas,
        });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account]
  );
  return { refundTask: refundTask };
};
export default RefundTask;