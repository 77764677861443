import React from 'react'
import "./about.scss"

const About = () => {
  return (
    <>
      <section className="about-section" id='about'>
        <img src="\assets\about\about-right-img.png" alt="img" className='img-fluid about-right-img' />
        <div className="custom-container">
            <div className="main-content">
                {/* <h6 className="subtitle">About Tasks</h6>
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</h4> */}
                <div className="bottom-text">
                    <h6>Why Use Tasks?</h6>
                    <div className="parent-multi">
                        <div className="single-text">
                            <h5>Trustless & Transparent</h5>
                            <p>Tasks are verified by the task creator and/or the community, ensuring fairness without intermediaries.</p>
                        </div>
                        <div className="single-text">
                            <h5>Real-World & Digital</h5>
                            <p>Whether it’s physical tasks like custom art, or digital work like software development, Tasks supports it all.</p>
                        </div>
                        <div className="single-text">
                            <h5>Decentralized Governance</h5>
                            <p>Disputes are resolved by a community-driven DAO, ensuring no bias or central authority.</p>
                        </div>
                        <div className="single-text">
                            <h5>Built on Blockchain</h5>
                            <p>Using Ethereum’s secure and transparent blockchain, with future plans to deploy on the Incentiv blockchain.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default About
