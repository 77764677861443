import React, { useEffect, useState, useRef } from 'react'
import "./editprofile.scss"
import Navbar from '../landing/header/Navbar'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";
const Editprofile = () => {
    const [show6, setShow6] = useState(false);
    const handleClose6 = () => {setShow6(false)
        history('/')
    };
    const handleShow6 = () => setShow6(true);
    const [name, setName] = useState(null)
    const [imge, setImage] = useState(null)
    const [inputField, setInputField] = useState(null)
    const fileInputRef = useRef(null);
    const [inputFieldImge, setInputFieldImge] = useState(null)
    const [bannerImage, setBannerImage] = useState(null)
    const [projectBannerImg, setProjectBannerImg] = useState(null)
    const [apiImage, setApiImage] = useState(null)
    const history = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const aditProfile = async () => {
        if(inputField||apiImage){
        let accessToken = localStorage.getItem('accessToken')
        var config = {
            method: "patch",
            url: `${API_URL}users`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },
            data: {
                name: inputField,
                imageUrl: apiImage
            },
        }
        try {
            let response = await axios(config)

            if (response) {
                handleShow6()


            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            toast.error("Image & Name is Required")
        }
    }else{
        toast.info("alteast one filed is requird")
    }
    }

    const handleBannerClick = () => {
        fileInputRef.current.click();
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];

        // Allowed file types
  const allowedFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];


        if (!allowedFileTypes.includes(file.type)) {
            console.log('chal gaya');
            toast.error("Only PNG, JPG, and JPEG files are allowed.");
            return;
        }
        const imageUrl = URL.createObjectURL(file);
        console.log(file, imageUrl, 'imageUrlimageUrlimageUrl');
        setBannerImage(file);
        setProjectBannerImg(imageUrl);
        imgeUrl(file)
        // setImageUploaded(true);
        // setBannerImageError('');
    };

    const imgeUrl = async (e) => {
        console.log(e, 'e');
        let accessToken = localStorage.getItem('accessToken');

        // Create a FormData object to send the image
        let formData = new FormData();
        formData.append('image', e);  // Assuming 'e' is the image file

        var config = {
            method: "post",
            url: `${API_URL}metadata/upload-image`,
            headers: {
                'Content-Type': 'multipart/form-data',  // Correct content type for file uploads
                'Authorization': `Bearer ${accessToken}`
            },
            data: formData,  // Send formData with the image file
        };

        try {
            let response = await axios(config);
            if (response) {
                console.log(response.data.url, 'response');
                setApiImage(response.data.url)
            }
        } catch (error) {
            console.error('Error occurred while uploading image:', error);
        }
    };
    const inputHandler=(e)=>{
setInputField(e.target.value)
    }

    const getProfile = async () => {
        let accessToken = localStorage.getItem('accessToken')
        var config = {
            method: "get",
            url: `${API_URL}users`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },
         
        }
        try {
            let response = await axios(config)

            if (response) {
                console.log('Task created successfully:', response.data.data);
                setProjectBannerImg(response.data.data.imageUrl)
                setInputField(response.data.data.name)
                setApiImage(response.data.data.imageUrl)

            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
        }
    }

    useEffect(()=>{
        getProfile()
    },[])
    return (
        <>
            <Navbar />
            <section className='mainedit'>
                <div className='custom-container'>
                    <a href='' target='_blank' className='edithead'>Home / <span> Edit Profile</span></a>
                    <h2 className='heading'>Edit Profile</h2>
                    <div className='mainbox'>
                        <div className='left'>
                            <div className='innerleft'>
                                <div className='first'>
                                    <div className='mainimg' >
                                        <img src={projectBannerImg ? projectBannerImg : '/assets/edituploadimg.png'} alt='img' className='img-fluid innerimg' />
                                    </div>
                                </div>
                                <div className='second'>
                                    <h4>Profile Image</h4>
                                    <p>For best results, upload an image with a resolution of 500x500 pixels. Please use JPEG or PNG format, with a maximum file size of 5 MB.</p>
                                    <div className='parentbuttonss'>
                                        {
                                            projectBannerImg &&  <button onClick={() => setProjectBannerImg("")} className='remove'>Remove</button>
                                        }
                                       
                                        <div className='wrapper' onClick={handleBannerClick}>
                                            <label htmlFor='' className='uploadbtn'>
                                                Change
                                            </label>
                                            <input ref={fileInputRef} onChange={handleImageUpload} type='file' id='' placeholder='hhhhhhhhhh' className='d-none' />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='right'>
                            <div className='maininput'>
                                <p>Full Name</p>
                           
                                <input type='text' placeholder='enter your name'  value={inputField} onChange={inputHandler}/>
                            </div>
                            <div className='twicebutton'>
                                <button className='back' onClick={handleClose6}>Go Back</button>
                                <button onClick={aditProfile} className='save'>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal show={show6} onHide={handleClose6} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="28.2036" y="49.8564" width="2" height="40.2235" transform="rotate(-135 28.2036 49.8564)" fill="#013159" />
                            <rect x="17" y="38.625" width="2" height="15.43" transform="rotate(-45 17 38.625)" fill="#013159" />
                        </svg>
                        <h1>Profile Successfully Edited</h1>
                        {/* <p className="parssa">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose6}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Editprofile