import { useCallback, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { getLibraryForSign } from "../utils/web3React";
// import useLibrary from "./useLibrary";
import { ethers } from "ethers";
import useWeb3 from "../hooks/useWeb3";
import { connectorsByName } from "../utils/web3React";


const useSignature = (wab) => {
  const web3 = useWeb3()
  const { account } = useWeb3React();
  // const { library1, connectorId } = useLibrary();
  let library = null
  // if (web3 && account) {
    const connectorId = typeof window !== 'undefined' ? window.localStorage.getItem("connectorId") : null;
    if (connectorId === 'injected' && account) {
      library = getLibraryForSign(web3?.givenProvider);
      localStorage.setItem('device','metamask')
    } else {
      if (connectorsByName.walletconnect.provider) {
        let devi=connectorsByName?.walletconnect?.provider?.signer?.session?.peer?.metadata?.name
        localStorage.setItem('device',devi)
        library = getLibraryForSign(connectorsByName.walletconnect.provider)
      }
    }



  const sign = useCallback(
    async (addrss,id) => {
      const connectorId = window.localStorage.getItem("connectorId")
      // console.log("Ssoos", web3)
      if (connectorId === 'injected' && account) {
        library = getLibraryForSign(web3?.givenProvider);
      } else {
        // return console.log("connectorsByName", connectorsByName['walletconnect'])
        if (connectorsByName?.walletconnect?.provider) {
          library = getLibraryForSign(connectorsByName?.walletconnect?.provider);
        }
      }
      // console.log("oooow", library)
      if (library && account) {
        console.log("web3========================")
        try {
          const hash = web3.utils.soliditySha3(
            { type: "address", value: addrss },
            { type: "uint256", value: id },
            { type: "uint8", value: 0 }
          );
          const message = `${addrss}${id}0`; // Concatenate your data
// const hexMessage = web3.utils.utf8ToHex(message);
          let signmessage = await web3.eth.personal.sign(
            hash,
            account
          );
          return signmessage;
        } catch (error) {
          console.log("!!!!!!!!xx", error)
          throw error;
        }
      }
    },
    [account, web3,connectorId]
  );

  return { userSign: sign };
};

export default useSignature;