import React, { useEffect, useState } from 'react'
import { API_URL } from '../../utils/ApiUrl';
import TaskTimer from './TaskTimer';
import Timer from '../landing/LatestTask/Timer';
import { Link, useParams } from 'react-router-dom';

function SimilarTasks({ taskId, categoryId, handleShow }) {
    const [tasks, setTasks] = useState([]);
    const [userId, setUserId] = useState(0);
    const { id } = useParams(); // Get the task ID from the URL
    const getTasks = async ({ limit = 5, offset = 1, categoryId = null, orderField = 'createdAt', orderDirection = 'ASC' } = {}) => {
        const accessToken = localStorage.getItem('accessToken');

        // Construct query parameters
        const queryParams = new URLSearchParams({
            limit,
            offset,
            orderField,
            orderDirection
        });

        if (categoryId) {
            queryParams.append('categoryId', categoryId); // Only include if categoryId is provided
        }

        try {
            const response = await fetch(`${API_URL}tasks?${queryParams.toString()}`, {
                method: 'GET',
                headers: accessToken ? {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                } : {
                    'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data?.data?.tasks, 'datadata');
                let destuctData = data?.data?.tasks
                let filtData = destuctData?.filter((item) => item?.id != taskId)
                filtData = filtData?.slice(0, 3)
                console.log(filtData, 'datadata dfdf');
                setTasks(filtData || []); // Set tasks in state
            } else {
                console.error('Failed to fetch tasks:', response.statusText);
            }
        } catch (error) {
            console.error('Error occurred while fetching tasks:', error);
        }
    };
    const tokenImages = {
        ETH: "/assets/createtask/eth.svg",
        USDT: "/assets/createtask/usdtT.svg",
        USDC: "/assets/createtask/usdc.svg",
        LINK: "/assets/createtask/link.svg",
        PEPE: "/assets/createtask/pepe.svg",
        UNI: "/assets/createtask/uni.svg",
        DOP: "/assets/createtask/dop1.svg",
        WBTC: "/assets/createtask/wbtc.svg"
    };
    useEffect(() => {
        getTasks({ limit: 5, offset: 1, categoryId });
        let userId = localStorage.getItem('userId');
        setUserId(userId)
    }, [categoryId, id]);
    console.log(tasks, 'taskstasks');

    return (
        <div className="similarcardsmain">
            <div className="custom-container paddzeroformbl">
                <div className="parentboxes">
                    {tasks && tasks.length > 0 ? (
                        tasks.map((item, id) => (
                            <div className="parent-box">
                                <h4 className="parentboxhead">
                                    {item?.title?.length > 20 ? item?.title?.slice(0, 20) + '...' : item?.title}
                                </h4>
                                <div className="upper-area">
                                    
                                    <div className="first-div">
                                        <p>Participants</p>
                                        <div className="multi-imgs">

                                            <div className="imgs-parent">
                                                {
                                                    item?.participants?.slice(0, 4).map((e, i) => {
                                                        let classn
                                                        if (i == 0) {
                                                            classn = 'one'
                                                        } else if ((i == 1)) {
                                                            classn = 'two'
                                                        } else if ((i == 2)) {
                                                            classn = 'three'
                                                        } else {
                                                            classn = 'four'
                                                        }
                                                        // if(i==3){
                                                        //     return
                                                        // }
                                                        return (
                                                            <img src={e?.user?.imageUrl
                                                                ? (e.user.imageUrl.startsWith('http')
                                                                    ? e.user.imageUrl
                                                                    : `https://${e.user.imageUrl}`)
                                                                : '/assets/edituploadimg.png'} alt="participantimg" className="img-fluid" />

                                                        )
                                                    })}
                                                {/* <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' /> */}
                                            </div>
                                            {item?.participantCount > 4 && <span>{item?.participantCount - 4}</span>}
                                            {item?.participantCount === '0' && <span>{item?.participantCount}</span>}
                                        </div>
                                    </div>
                                    <div className="first-div">
                                        <p>Created By</p>
                                        <h6>{item?.user?.imageUrl ? <img src={item?.user?.imageUrl} alt="img" className='img-fluid' /> : null}{item?.user?.name || item?.user?.walletAddress?.slice(0, 5) + '...' + item?.user?.walletAddress?.slice(-4)}</h6>
                                    </div>
                                    <div className="first-div">
                                        <p>Reward</p>
                                        <h6><img src={tokenImages[item?.currencySymbol]} alt={item?.currencySymbol} srcSet="" /> {item?.reward} {item?.currencySymbol}</h6>
                                    </div>
                                    <div className="first-div">
                                        <p>TX HASH</p>
                                        <h6><a target="_blank" href={`https://etherscan.io/tx/${item?.taskTransactionHash}`}>{item?.taskTransactionHash?.slice(0, 5)}...{item?.taskTransactionHash?.slice(-2)}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M8.33333 4.66667H6.83333C5.89991 4.66667 5.43285 4.66667 5.07633 4.84832C4.76273 5.00811 4.50795 5.2629 4.34816 5.5765C4.1665 5.93302 4.1665 6.40008 4.1665 7.3335V13.6668C4.1665 14.6003 4.1665 15.0667 4.34816 15.4232C4.50795 15.7368 4.76273 15.9921 5.07633 16.1519C5.43251 16.3333 5.899 16.3333 6.83059 16.3333H13.1691C14.1007 16.3333 14.5665 16.3333 14.9227 16.1519C15.2363 15.9921 15.4919 15.7366 15.6517 15.423C15.8332 15.0668 15.8332 14.6008 15.8332 13.6692V12.1667M16.6665 8V3.83334M16.6665 3.83334H12.4998M16.6665 3.83334L10.8332 9.66667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a></h6>
                                    </div>
                                </div>
                                <Timer endTime={item?.endTime} />
                                {/* onClick={handleShow} */}
                                {((new Date(item?.endTime) > new Date()) && (userId != item?.userId) && !item?.hasParticipated) && (<Link to={`/similartaskdetail/${item?.id}`} className='btn-part'>Participate</Link>)}
                            </div>
                        ))
                    ) : (
                        <h6 className='nodata-text'>No task found!</h6>
                    )}

                </div>
            </div>

        </div>
    )
}

export default SimilarTasks