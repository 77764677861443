import React, { useEffect, useState } from 'react';

const TaskTimer = ({ endTime }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    // Function to calculate the remaining time
    function calculateTimeLeft() {
        const difference = new Date(endTime) - new Date(); // Calculate time difference
        // console.log(  difference, new Date(endTime) , new Date(),'difference');
        let timeRemaining = {};

        if (difference > 0) {
            timeRemaining = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            timeRemaining = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        return timeRemaining;
    }

    // Use useEffect to update the timer every second
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        // Clear the interval when component unmounts
        return () => clearInterval(timer);
    }, [endTime]);

    return (

      
                                        <div className="timerspans">
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{timeLeft.days} D</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{timeLeft.hours} H</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{timeLeft.minutes} M</span>
                                                </div>
                                            </div>
                                            <p className="colonpara">
                                                :
                                            </p>
                                            <div className="maintime seconds">
                                                <div className="innertimer">
                                                    <span className="innertimertext">{timeLeft.seconds} S</span>
                                                </div>
                                            </div>
                                        </div>
                             
    );
};

export default TaskTimer;
