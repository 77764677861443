import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { taskManagerContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const RedeemTask1 = () => {

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tastManager;
  const contract = taskManagerContract(tokenAddress, web3);


  const redeemTask1 = useCallback(
    async (creater,personalsing) => {
      console.log(creater?.signedKeyDetails?.taskId,creater?.signedKeyDetails?.signerSignature,'taskId');
      try {
        const gas = await contract.methods.disputeTask(creater?.signedKeyDetails?.taskId,creater?.signedKeyDetails?.signerSignature).estimateGas({
            from:account });
        const details = await contract.methods.disputeTask(creater?.signedKeyDetails?.taskId,creater?.signedKeyDetails?.signerSignature).send({
          from: account,
          gas,
        });
        return details;
      } catch (error) {
        throw error;
      }
    },
    [contract, account,web3]
  );
  return { redeemTask1: redeemTask1 };
};
export default RedeemTask1;