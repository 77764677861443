import React, { useState, useEffect } from 'react'
import './taskparticipated.scss'
import Pagination from 'react-bootstrap/Pagination';
import { Accordion, Modal } from 'react-bootstrap';
import Navbar from '../landing/header/Navbar';
import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl'
import { toast } from 'react-toastify'
import moment from 'moment';
import ReactPaginate from "react-paginate";
import Loader from '../../hooks/loader';
import RedeemTask from '../../hooks/write/redeemTask';
import RedeemTask1 from '../../hooks/write/redeemTask1';
import { Link } from 'react-router-dom';

const Taskparticipated = () => {
    const { redeemTask1 } = RedeemTask1()
    const { redeemTask } = RedeemTask()
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);
    const [show5, setShow5] = useState(false);
    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const [show6, setShow6] = useState(false);
    const handleClose6 = () => setShow6(false);
    const handleShow6 = () => setShow6(true);

    const [loader, setLoader] = useState(false);
    const [pages, setPages] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [arry, setArry] = useState([])
    const [winnerData, setWinnerData] = useState([])
    const [creater, setCreat] = useState(null)
    const [selectedIs, setSelectedIs] = useState(null)
    const [dataaa, setdatata] = useState(null)
    const handlePageChange = (e) => {
        console.log("called");
        const selectedPage = e.selected;
        setPage(selectedPage);
    };
    const getProfile = async () => {
        setLoader(true);
        let accessToken = localStorage.getItem('accessToken')
        var config = {
            method: "get",
            url: `${API_URL}participants/my?limit=${5}&offset=${page + 1
                }`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },
        }
        try {
            let response = await axios(config)

            if (response) {
                let vale = response.data.data
                console.log('Task created successfully:', response.data.data);
                setPageCount(vale.count / 5);
                setPages(vale.pages);
                setArry(vale.participations)
                setLoader(false);

            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
            // toast.error(error?.message);
        }
    }
    useEffect(() => {
        getProfile()
    }, [page])


    const claimToken = async () => {
        let sign = localStorage.getItem("sign")
        // console.log(winnerData?.task, 'sddsadasasd');\
        console.log(creater, sign, 'signsignsign');
        setLoader(true);
        try {
            let value = await redeemTask(creater, sign)
            if (value) {
                handleShow5();
                handleClose4();
                aditProfile()
                setLoader(false);
            }
        } catch (err) {
            console.log(err, "contract erro");
            setLoader(false);
            toast.error("Something went wrong!");
        }
    }
    const claimToken1 = async () => {
        let sign = localStorage.getItem("sign")
        // console.log(winnerData?.task, 'sddsadasasd');\
        console.log(creater, sign, 'signsignsign');
        setLoader(true);
        try {
            let value = await redeemTask1(creater, sign)
            if (value) {
                handleShow5();
                handleClose6()
                aditProfile()
                setLoader(false);
            }
        } catch (err) {
            console.log(err, "contract erro");
            setLoader(false);
            toast.error("Something went wrong!");
        }
    }
    const aditProfile = async () => {
        let accessToken = localStorage.getItem('accessToken')
        setLoader(true);
        var config = {
            method: "patch",
            url: `${API_URL}participants/${selectedIs}/claim-reward`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },
            data: {
                "isClaimed": true
            },
        }
        try {
            let response = await axios(config)

            if (response) {
                getProfile()
                setLoader(false);
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
            toast.error(error.message)
        }

    }
    const apiCallHandler = async (e) => {
        setdatata(e)
        setLoader(true);
        setSelectedIs(e?.id)
        let accessToken = localStorage.getItem('accessToken')
        let signg = localStorage.getItem('sign')
        var config = {
            method: "get",
            url: `${API_URL}tasks/${e?.task?.id}/signature`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },

        }
        try {
            let response = await axios(config)

            if (response) {
                console.log('Task created successfully:', response.data.data);
                handleShow4()
                setCreat(response.data.data)
                setLoader(false);
                //    setWinnerData(e)
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
        }
    }
    const apiCallHandler1 = async (e) => {
        setdatata(e)
        setLoader(true);
        setSelectedIs(e?.id)
        let accessToken = localStorage.getItem('accessToken')
        let signg = localStorage.getItem('sign')
        var config = {
            method: "get",
            url: `${API_URL}tasks/${e?.task?.id}/signature/dispute`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`  // Include the access token here
            },

        }
        try {
            let response = await axios(config)

            if (response) {
                console.log('Task created successfully:', response.data.data);
                handleShow6()
                setCreat(response.data.data)
                setLoader(false);
                //    setWinnerData(e)
            }
        } catch (error) {
            console.error('Error occurred while creating task:', error);
            setLoader(false);
        }
    }
    return (
        <>
            {loader && <Loader />}
            <Navbar />
            <section className="taskscreated">
                <img src="/assets/importantassets/backshade.png" alt="backshade" className="backshade" />
                <img src="/assets/importantassets/backshadembl.png" alt="backshade" className="backshadembl d-none" />
                <div className="custom-container">
                    <p className="toppara">
                        <span className="light">Home </span>
                        / Tasks I Participated in
                    </p>
                    <h2 className="createdhead">Tasks I Participated in</h2>
                    <div className="createdtasktable">
                        {
                            arry.length > 0 ?

                                <table>
                                    <thead>
                                        <th>Task Name</th>
                                        <th>Reward</th>
                                        <th>Ends in</th>
                                        <th>Transaction Hash</th>
                                        <th>Dispute Period</th>
                                        <th>Voting Period</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>

                                        {
                                            arry?.map((e) => {
                                                const targetDate = moment(e?.task?.endTime);
                                                const currentDate = moment();
                                                const duration = moment.duration(targetDate.diff(currentDate));
                                                let formattedDuration = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                                                const currentEpochSeconds = Math.floor(Date.now() / 1000);
                                                const date = new Date(e?.task?.endTime);
                                                const epochSeconds = Math.floor(date.getTime() / 1000);


                                                const targetDate1 = moment(e?.task?.coolDownPeriod);
                                                const currentDate1 = moment();
                                                const duration1 = moment.duration(targetDate1.diff(currentDate1));
                                                let formattedDuration1 = `${Math.floor(duration1.asDays())}d ${duration1.hours()}h ${duration1.minutes()}m ${duration1.seconds()}s`;

                                                const votingEndPeriod = moment(e?.task?.votingEndPeriod);
                                                const currentDate134 = moment();
                                                const votingEndPeriodM = moment.duration(votingEndPeriod.diff(currentDate134));
                                                let votingEndPeriodFormated = `${Math.floor(votingEndPeriodM.asDays())}d ${votingEndPeriodM.hours()}h ${votingEndPeriodM.minutes()}m ${votingEndPeriodM.seconds()}s`;

                                                const currentEpochSeconds1 = Math.floor(Date.now() / 1000);
                                                const date1 = new Date(e?.task?.coolDownPeriod);
                                                const dateVT = new Date(e?.task?.votingEndPeriod);
                                                const epochSeconds1 = Math.floor(date1.getTime() / 1000);
                                                const epochSecondsVT = Math.floor(dateVT.getTime() / 1000);
                                                let status = ''
                                                if (e?.status == "submitted" || e?.status == "pending") {
                                                    status = e?.status
                                                }
                                                else if (e?.status == "ended") {
                                                    status = "ended"
                                                }
                                                else if (e?.status == "winner") {
                                                    status = "winner"
                                                }
                                                else if (e?.status == "disputed") {
                                                    status = "disputed"
                                                }
                                                return (
                                                    <tr>
                                                        <td>
                                                            <Link to={`/similartaskdetail/${e?.task?.id}`}>
                                                                <p data-toggle="tooltip" data-placement="top" title={e?.task?.title} className="taskpara">{e?.task?.title}</p>
                                                            </Link>
                                                        </td>

                                                        <td>
                                                            <p className="tablepara">{e?.task?.reward} {e?.task?.currencySymbol}</p>
                                                        </td>
                                                        <td>
                                                            <p className="tablepara">{currentEpochSeconds <= epochSeconds && status !== "winner" ? formattedDuration : " time Ended"}</p>
                                                        </td>
                                                        <td>
                                                            <a target="_blank" href={`https://etherscan.io/tx/${e?.task?.taskTransactionHash}`}>
                                                                hash <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                    <path d="M8.33333 4.66667H6.83333C5.89991 4.66667 5.43285 4.66667 5.07633 4.84832C4.76273 5.00811 4.50795 5.2629 4.34816 5.5765C4.1665 5.93302 4.1665 6.40008 4.1665 7.3335V13.6668C4.1665 14.6003 4.1665 15.0667 4.34816 15.4232C4.50795 15.7368 4.76273 15.9921 5.07633 16.1519C5.43251 16.3333 5.899 16.3333 6.83059 16.3333H13.1691C14.1007 16.3333 14.5665 16.3333 14.9227 16.1519C15.2363 15.9921 15.4919 15.7366 15.6517 15.423C15.8332 15.0668 15.8332 14.6008 15.8332 13.6692V12.1667M16.6665 8V3.83334M16.6665 3.83334H12.4998M16.6665 3.83334L10.8332 9.66667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                                </svg>
                                                            </a>
                                                        </td>
                                                        <td>
                                                            <p className="tablepara">{currentEpochSeconds1 <= epochSeconds1 ? formattedDuration1 : " CoolDown Period Ended"}</p>
                                                        </td>
                                                        <td>
                                                            {console.log(currentEpochSeconds1, votingEndPeriodFormated, 'currentEpochSeconds1 <= votingEndPeriodFormated')
                                                            }
                                                            <p className="tablepara w-100">{(currentEpochSeconds1 <= epochSecondsVT && e?.task?.status === 'inDispute') ? votingEndPeriodFormated : "Not in voting"}</p>
                                                        </td>
                                                        <td>
                                                            <div className="creatormain">
                                                                <div className="creatormainimg">
                                                                    <img src={e?.task?.user?.imageUrl ? e?.task?.user?.imageUrl : '/assets/edituploadimg.png'} alt="creatorimg" className="creatorimg" />
                                                                </div>
                                                                <p className="creatorwallet">{e?.task?.user?.name ? e?.task?.user?.name : "anonymous"}</p>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <p className="tablepara"><span className={status == "submitted" || status == "pending" ? "progressbtn" : status == "ended" ? "endedbtn" : status == "winner" ? "reviewbtn" : "disputedbtn"}>{status}</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            {((status == 'winner') && (e?.isClaimed == false) && (currentEpochSeconds1 >= epochSeconds1) && (e?.task?.status !== 'inDispute')) ?
                                                                <>
                                                                    {e?.task?.disputeWinnerId ?

                                                                        <button className="actionbtn w-100" onClick={() => {
                                                                            apiCallHandler1(e)
                                                                        }}>Claim reward</button>
                                                                        :
                                                                        <button className="actionbtn w-100" onClick={() => {
                                                                            apiCallHandler(e)
                                                                        }}>Claim reward</button>
                                                                    }
                                                                </>
                                                                :
                                                                <button className="diablebtn" >Claim reward</button>
                                                            }


                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table> :
                                <><>
                                    <table>
                                        <thead>
                                            <th>Task Name</th>
                                            <th>Reward</th>
                                            <th>Ends in</th>
                                            <th>Dispute Period</th>
                                            <th>Created By</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </thead>
                                        <tbody>
                                            <tr><td colSpan={7}> <h6 className='nodata-text'>No Data Found!</h6></td></tr>
                                        </tbody>
                                    </table>
                                </></>
                        }
                        <div className="paginationmain">
                            {/* <div className="left-f">
          <h6>
                {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
          </div> */}
                            {pages > 1 &&
                                <ReactPaginate
                                    previousLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M10 13L5 8L10 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    nextLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M5 13L10 8L5 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={page}
                                ></ReactPaginate>
                            }
                        </div>
                    </div>
                    <div className="createdtaskmbl d-none">
                        <div className="acctop">
                            <p className="taskpara">
                                Task name
                            </p>
                            <p className="taskpara">
                                Status
                            </p>
                        </div>

                        {
                            arry.length > 0 ? <Accordion defaultActiveKey="0">
                                {
                                    arry.map((e, i) => {
                                        const targetDate = moment(e?.task?.endTime);
                                        const currentDate = moment();
                                        const duration = moment.duration(targetDate.diff(currentDate));

                                        let status = ''
                                        if (e?.status == "submitted" || e?.status == "pending") {
                                            status = e?.status
                                        }
                                        else if (e?.status == "ended") {
                                            status = "ended"
                                        }
                                        else if (e?.status == "winner") {
                                            status = "winner"
                                        }
                                        else if (e?.status == "disputed") {
                                            status = "disputed"
                                        }

                                        let formattedDuration = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                                        const currentEpochSeconds = Math.floor(Date.now() / 1000);
                                        const date = new Date(e?.task?.endTime);
                                        const epochSeconds = Math.floor(date.getTime() / 1000);

                                        const targetDate1 = moment(e?.task?.coolDownPeriod);
                                        const currentDate1 = moment();
                                        const duration1 = moment.duration(targetDate1.diff(currentDate1));
                                        let formattedDuration1 = `${Math.floor(duration1.asDays())}d ${duration1.hours()}h ${duration1.minutes()}m ${duration1.seconds()}s`;
                                        const currentEpochSeconds1 = Math.floor(Date.now() / 1000);
                                        const date1 = new Date(e?.task?.coolDownPeriod);
                                        const epochSeconds1 = Math.floor(date1.getTime() / 1000);





                                        return (
                                            <Accordion.Item eventKey={i}>
                                                <Accordion.Header>
                                                    <div className="taskmain">
                                                        <p className="innertaskpara" data-bs-toggle="tooltip" data-toggle="tooltip" data-placement="top" title={e?.task?.title}>
                                                            {e?.task?.title?.slice(0, 15)}
                                                        </p>
                                                    </div>

                                                    <div className="statusmain">
                                                        <span className={status == "submitted" || status == "pending" ? "progressbtn" : status == "ended" ? "endedbtn" : status == "winner" ? "reviewbtn" : "disputedbtn"}>{status}</span>
                                                    </div>

                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="mainacc">
                                                        <div className="inneracc">
                                                            <p className="leftacc">Reward</p>
                                                            <p className="rightacc">{e?.task?.reward} {e?.task?.currencySymbol}</p>
                                                        </div>
                                                        <div className="inneracc">
                                                            <p className="leftacc">Ends in</p>
                                                            <p className="rightacc">{currentEpochSeconds <= epochSeconds && status !== "winner" ? formattedDuration : " time Ended"}</p>
                                                        </div>
                                                        <div className="inneracc">
                                                            <p className="leftacc">Created By</p>
                                                            <div className="creatormain">
                                                                <div className="creatormainimg">
                                                                    <img src={e?.task?.user?.imageUrl ? e?.task?.user?.imageUrl : '/assets/edituploadimg.png'} alt="creatorimg" className="creatorimg" />
                                                                </div>
                                                                <p className="creatorwallet">{e?.task?.user?.name ? e?.task?.user?.name : "anonymous"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {((status == 'winner') && (e?.isClaimed == false) && (currentEpochSeconds1 >= epochSeconds1) && (e?.task?.status !== 'inDispute')) ?
                                                        <>
                                                            {e?.task?.disputeWinnerId ?
                                                                <button className="actionbtn" onClick={() => {
                                                                    apiCallHandler1(e)
                                                                }}>Claim reward</button>
                                                                :
                                                                <button className="actionbtn" onClick={() => {
                                                                    apiCallHandler(e)
                                                                }}>Claim reward</button>
                                                            }
                                                        </>
                                                        :
                                                        <button className="diablebtn" >Claim reward</button>
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        )
                                    })
                                }


                            </Accordion> : <><h6 className='nodata-text'>No Data Found!</h6></>
                        }
                        <div className="paginationmain">
                            {pages > 1 &&
                                <ReactPaginate
                                    previousLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M10 13L5 8L10 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    nextLabel={
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="15"
                                            height="15"
                                            viewBox="0 0 15 15"
                                            fill="none"
                                        >
                                            <path
                                                d="M5 13L10 8L5 3"
                                                stroke="#fff"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    }
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    forcePage={page}
                                ></ReactPaginate>
                            }
                        </div>
                    </div>
                </div>
            </section>


            <Modal show={show5} onHide={handleClose5} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="28.2036" y="49.8564" width="2" height="40.2235" transform="rotate(-135 28.2036 49.8564)" fill="#013159" />
                            <rect x="17" y="38.625" width="2" height="15.43" transform="rotate(-45 17 38.625)" fill="#013159" />
                        </svg>
                        <h1>{dataaa?.task?.reward} {dataaa?.task?.currency} Successfully Withdrawn!</h1>
                        {/* <p className="parssa">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">

                        <button className="surebutton" onClick={handleClose5}>OK</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Claim Reward</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="34" y="18" width="2" height="28" fill="#013159" />
                            <rect x="34" y="49" width="2" height="3" fill="#013159" />
                        </svg>
                        <h1>Are you sure you want to claim <br />
                            {winnerData?.task?.reward} {winnerData?.task?.currencySymbol} for this task?</h1>
                        <p className="parssa">The reward sum will be transferred to your connected wallet.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose4}>Cancel</button>
                        <button className="surebutton" onClick={() => {
                            {
                                claimToken()
                            }

                        }}>Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>




            <Modal show={show6} onHide={handleClose6} className="modal_mainr" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Claim Reward</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal4">
                        <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                            <rect x="5" y="5" width="60" height="60" stroke="#013159" stroke-width="2" />
                            <rect x="34" y="18" width="2" height="28" fill="#013159" />
                            <rect x="34" y="49" width="2" height="3" fill="#013159" />
                        </svg>
                        <h1>Are you sure you want to claim <br />
                            {winnerData?.task?.reward} {winnerData?.task?.currencySymbol} for this task?</h1>
                        <p className="parssa">The reward sum will be transferred to your connected wallet.</p>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="modal_footer">
                        <button className="cancelbutton" onClick={handleClose6}>Cancel</button>
                        <button className="surebutton" onClick={() => {
                            claimToken1()
                        }}>Yes, I’m Sure</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>

    )
}

export default Taskparticipated
