import React from 'react'
import "./how.scss"

const How = () => {
    return (
        <>
            <section className="how-section" id='howitworks'>
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="main-heading">
                                <h6>How It Works</h6>
                                <p>Tasks is a decentralized platform where users can create and complete tasks in a trustless environment. Create a task, set a reward, and let the community participate. Every task is verified by the community using blockchain technology, ensuring fairness and transparency in reward distribution.</p>
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="side-content">
                                <div className="single-content">
                                    <div className="inner-left">
                                        <div className="inside-content">
                                            <h6>01/<span>04</span> </h6>
                                            <h4>Create a Task</h4>
                                            <p>Create a new task, set the reward for successful completion, <br /> and post your task.</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <img src="\assets\how\1.svg" alt="img" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="single-content">
                                    <div className="inner-left">
                                        <div className="inside-content">
                                            <h6>02/<span>04</span> </h6>
                                            <h4>Submit Proof</h4>
                                            <p>Users complete the task and submit proof of their <br /> work.</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <img src="\assets\how\2.svg" alt="img" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="single-content">
                                    <div className="inner-left">
                                        <div className="inside-content">
                                            <h6>03/<span>04</span> </h6>
                                            <h4>DAO Validation</h4>
                                            <p>The community verifies the proof through a <br /> decentralized voting process.</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <img src="\assets\how\3.svg" alt="img" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="single-content">
                                    <div className="inner-left">
                                        <div className="inside-content">
                                            <h6>04/<span>04</span> </h6>
                                            <h4>Earn Rewards</h4>
                                            <p>Once successful task completion verified, the reward <br /> is distributed automatically, with 1% going to the DAO.</p>
                                        </div>
                                    </div>
                                    <div className="inner-right">
                                        <img src="\assets\how\4.svg" alt="img" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default How
