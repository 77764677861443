import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactPaginate from "react-paginate";
import Navbar from '../header/Navbar';
import Timer from './Timer';
import "./latesttask.scss";
import { API_URL } from '../../../utils/ApiUrl';
import Loader from '../../../hooks/loader';
import { toast } from 'react-toastify';

const LatestTask = () => {
    const [tasks, setTasks] = useState([]);
  const [userId, setUserId] = useState(0);
  const [visibleTasks, setVisibleTasks] = useState(3);
    const [categoryId, setCategoryId] = useState(null);  // null means 'All tasks'
  const [categories, setCategories] = useState([{
    categoryName:"Logistics & Supply Chain",
id: 15
}]);

    const [loader, setLoader] = useState(false); 

    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);

    const handlePageChange = (e) => {
      window.scrollTo(0, 0);
        console.log("called");
        const selectedPage = e.selected;
        setPage(selectedPage);
      };
    
    const history = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
      let userId = localStorage.getItem('userId');
      setUserId(userId)
    }, []);
    const tokenImages = {
        ETH: "/assets/createtask/eth.svg",
        USDT: "/assets/createtask/usdtT.svg",
        USDC: "/assets/createtask/usdc.svg",
        LINK: "/assets/createtask/link.svg",
        PEPE: "/assets/createtask/pepe.svg",
        UNI: "/assets/createtask/uni.svg",
        DOP: "/assets/createtask/dop1.svg",
        WBTC: "/assets/createtask/wbtc.svg"
    };
    
    // Function to fetch tasks from the API
    const getTasks = async ({ limit = 10, offset = {page}, categoryId = null, orderField = 'createdAt', orderDirection = 'DESC' } = {}) => {
        setLoader(true);
        const accessToken = localStorage.getItem('accessToken');

        // Construct query parameters
        const queryParams = new URLSearchParams({
            limit,
            offset,
            orderField,
            orderDirection
        });

        if (categoryId) {
            queryParams.append('categoryId', categoryId); // Only include if categoryId is provided
        }

        try {
            const response = await fetch( `${API_URL}tasks?${queryParams.toString()}`, {
                method: 'GET',
                headers: accessToken ? {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                } : {
                        'Content-Type': 'application/json',
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data, 'datadata');
                setLoader(false);
               if( history.pathname === '/'){
                 setTasks(data?.data?.tasks?.slice(0, 9) || []); // Limit to 9 tasks
               }else{
                   setTasks(data?.data?.tasks || []);
                   setPageCount(data?.data?.pages)
                   setLoader(false); // Set tasks in state
               }
                
            } else {
                // toast.error("Something went wrong!");
                console.error('Failed to fetch tasks:', response.statusText);
                setLoader(false);
            }
        } catch (error) {
            console.error('Error occurred while fetching tasks:', error);
            setLoader(false);
            toast.error(error?.message);
        }
    };
  const fetchTasksCategories = async () => {
    try {
      const response = await fetch(`${API_URL}tasks-categories?limit=${50}`, {
        method: 'GET', // HTTP method
        headers: {
          'Content-Type': 'application/json', // Set the content type if needed
          // No authorization header needed since you're not using an access token
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setCategories(data?.data)
      console.log(data?.data, 'datasdDS'); // Handle your data here
    } catch (error) {
      console.error('Error fetching tasks categories:', error);
    }
  };
  const handleSeeMore = () => {
    setVisibleTasks(prevVisibleTasks => prevVisibleTasks + 3);
  };
    // Call `getTasks` when the component mounts and whenever `categoryId` changes
    useEffect(() => {
        getTasks({ limit: 9, offset: page+1, categoryId });
    }, [categoryId,page]);

    // Handle category selection
    const handleCategorySelect = (categoryId) => {
        setCategoryId(categoryId);  // Update the selected category
    };
useEffect(() => {
  fetchTasksCategories()
}, [])
  console.log(history?.pathname, 'history?.pathname');

    return (
        <>
         {loader && <Loader/>}
            <div className="wrapper-latestsection">
                {history.pathname === '/latesttask' && <Navbar />}
                <section className={history.pathname === '/latesttask' ? "latest-task latesttask-section" : "latest-task"} id='latesttask'>
                    <div className="custom-container">
                        {history.pathname === '/latesttask' && (
                            <div className="breadcrumb-text">
                                <Link to="/">Home</Link>
                                <span>/</span>
                                <Link to="/latesttask" className='active'>Tasks</Link>
                            </div>
                        )}
                    </div>

                    <div className="main-heading">
                        <div className="custom-container">
                            <div className="parent-head">
                                <h6>{history.pathname === '/latesttask' ? "Tasks" : "Latest Tasks"}</h6>
                                <Link to="/latesttask" className='btn-view'>View All Tasks</Link>
                                <div className="choice-bar">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      {/* All Tasks Button */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="all-tasks-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#all-tasks-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="all-tasks-tab-pane"
                          aria-selected="true"
                          onClick={() => handleCategorySelect(null)} // 'null' for All tasks
                        >
                          All tasks
                        </button>
                      </li>

                      {/* Dynamically generated category buttons */}
                      {categories.map((category, index) => (
                        <li className="nav-item" role="presentation" key={index}>
                          <button
                            className="nav-link"
                            id={`${category?.categoryName?.toLowerCase().replace(/ & | /g, "-")}-tab`} // Unique id
                            data-bs-toggle="tab"
                            data-bs-target={`#${category?.categoryName?.toLowerCase().replace(/ & | /g, "-")}-tab-pane`}
                            type="button"
                            role="tab"
                            aria-controls={`${category?.categoryName?.toLowerCase().replace(/ & | /g, "-")}-tab-pane`}
                            aria-selected="false"
                            onClick={() => handleCategorySelect(category?.id)} // Using index + 1 as ID
                          >
                            {category?.categoryName}
                          </button>
                        </li>
                      ))}
                    </ul>
                                    {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"
                                                onClick={() => handleCategorySelect(null)}  // 'null' for All tasks
                                            >All tasks</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="music-tab" data-bs-toggle="tab" data-bs-target="#music-tab-pane" type="button" role="tab" aria-controls="music-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(1)}  // Category ID 1 for Music
                                            >Music</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="marketing-tab" data-bs-toggle="tab" data-bs-target="#marketing-tab-pane" type="button" role="tab" aria-controls="marketing-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(2)}  // Category ID 2 for Marketing
                                            >Marketing</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="design-tab" data-bs-toggle="tab" data-bs-target="#design-tab-pane" type="button" role="tab" aria-controls="design-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(3)}  // Category ID 3 for Design
                                            >Design</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="media-tab" data-bs-toggle="tab" data-bs-target="#media-tab-pane" type="button" role="tab" aria-controls="media-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(4)}  // Category ID 4 for Media
                                            >Media</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="crypto-tab" data-bs-toggle="tab" data-bs-target="#crypto-tab-pane" type="button" role="tab" aria-controls="crypto-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(5)}  // Category ID 5 for Crypto
                                            >Crypto</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="gaming-tab" data-bs-toggle="tab" data-bs-target="#gaming-tab-pane" type="button" role="tab" aria-controls="gaming-tab-pane" aria-selected="false"
                                                onClick={() => handleCategorySelect(6)}  // Category ID 6 for Gaming
                                            >Gaming</button>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom-content">
                        <div className="tab-content" >
                        <div className="tab-pane fade show active"  role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                <div className="parent-cards">
                                {tasks && tasks.length > 0 ? (
                      (history?.pathname === '/' ? tasks.slice(0, visibleTasks) : tasks).map((item, id) => (
    <div className="single-card" key={id}>
      <img src="\assets\latesttask\right-bg-layer.png" alt="img" className='img-fluid right-bg-layer' />
      <div className="custom-container">
        <div className="parent">
          <div className="left-side">
            <div className="main-content">
              <h6>{item?.title}</h6>
              {/* <p>{item?.description}</p> */}
              <div
                // Uncomment the next line if using DOMPurify
                // dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                dangerouslySetInnerHTML={{ __html: item?.description?.slice(0,500) }}
              />
            </div>
          </div>
          <div className="right-side">
            <div className="parent-box">
              <div className="upper-area">
                <div className="first-div">
                  <p>Participants</p>
                  <div className="multi-imgs">

                    <div className="imgs-parent">
                      {
                        item?.participants?.slice(0, 4).map((e, i) => {
                          let classn
                          if (i == 0) {
                            classn = 'one'
                          } else if ((i == 1)) {
                            classn = 'two'
                          } else if ((i == 2)) {
                            classn = 'three'
                          } else {
                            classn = 'four'
                          }
                          // if(i==3){
                          //     return
                          // }
                          return (
                            <img src={e?.user?.imageUrl
                              ? (e.user.imageUrl.startsWith('http')
                                ? e.user.imageUrl
                                : `https://${e.user.imageUrl}`)
                              : '/assets/edituploadimg.png'} alt="participantimg" className="img-fluid" />
                          )
                        })}
                      {/* <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
                                      <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' /> */}
                    </div>
                    {item?.participantCount > 4 && <span>{item?.participantCount - 4}</span>}
                    {item?.participantCount === '0' && <span>{item?.participantCount}</span>}
                    {item?.participants?.length === 0 && item?.participantCount > 0 && <span>{item?.participantCount}</span>}
                  </div>
                </div>
                <div className="first-div">
                  <p>Created By</p>
                  <h6>{item?.user?.imageUrl ? <img src={item?.user?.imageUrl.startsWith('http') ? item.user.imageUrl : `https://${item.user.imageUrl}`} alt="img" className='img-fluid' /> : null}{item?.user?.name ? item?.user?.name : item?.user?.walletAddress?.slice(0, 5) + '...' + item?.user?.walletAddress?.slice(-4)}</h6>
                </div>
                <div className="first-div">
                  <p>Reward</p>
                  <h6><img src={tokenImages[item?.currencySymbol]} alt={item?.currencySymbol} /> {item?.reward?.toLocaleString()} {item?.currencySymbol}</h6>
                </div>
                <div className="first-div">
                  <p>TX HASH</p>
                  <h6><a target="_blank" href={`https://etherscan.io/tx/${item?.taskTransactionHash}`}>{item?.taskTransactionHash?.slice(0, 5)}...{item?.taskTransactionHash?.slice(-2)}<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path d="M8.33333 4.66667H6.83333C5.89991 4.66667 5.43285 4.66667 5.07633 4.84832C4.76273 5.00811 4.50795 5.2629 4.34816 5.5765C4.1665 5.93302 4.1665 6.40008 4.1665 7.3335V13.6668C4.1665 14.6003 4.1665 15.0667 4.34816 15.4232C4.50795 15.7368 4.76273 15.9921 5.07633 16.1519C5.43251 16.3333 5.899 16.3333 6.83059 16.3333H13.1691C14.1007 16.3333 14.5665 16.3333 14.9227 16.1519C15.2363 15.9921 15.4919 15.7366 15.6517 15.423C15.8332 15.0668 15.8332 14.6008 15.8332 13.6692V12.1667M16.6665 8V3.83334M16.6665 3.83334H12.4998M16.6665 3.83334L10.8332 9.66667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg></a></h6>
                </div>
              </div>
              <Timer endTime={item?.endTime} />
              {/* {((new Date(item?.endTime) > new Date()) && (userId != item?.userId) && !item?.hasParticipated ) && ( */}
                <Link to={`/similartaskdetail/${item?.id}`} className="btn-part">
                 View Details 
                </Link>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    
    </div>
  ))
) : (
  <h6 className='nodata-text'>No task found!</h6>
)}
                    {history?.pathname === '/' && tasks.length > 3 && visibleTasks < tasks.length && (
                      <div className='seeMore'>
                        <button onClick={handleSeeMore}>See More</button>
                      </div>
                    )}

                                </div>
                            </div>
                        </div>
                        <div className="paginationmain">
                        {/* <div className="left-f">
          <h6>
                {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
          </div> */}
          {pageCount>1&&
                            <ReactPaginate
                      previousLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M10 13L5 8L10 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      nextLabel={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M5 13L10 8L5 3"
                            stroke="#fff"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName="pagination"
                      activeClassName="active"
                      forcePage={page}
                    ></ReactPaginate>
}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default LatestTask;


// import React, { useEffect, useState } from 'react'
// import "./latesttask.scss"
// import { Link, useLocation } from 'react-router-dom'
// import Navbar from '../header/Navbar';
// import Timer from './Timer';


// const LatestTask = () => {
//     const [tasks, setTasks] = useState([]);

//     const history = useLocation();
//     useEffect(() => {
//         window.scrollTo(0, 0)
//       }, [])
//     const getTasks = async ({ limit = 10, offset = 0, categoryId = null, orderField = 'createdAt', orderDirection = 'ASC' } = {}) => {
//         // Access token, either from state or local storage
//         const accessToken = localStorage.getItem('accessToken') || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6Miwid2FsbGV0QWRkcmVzcyI6IjB4OGQyMzMzNmM1ZjcwNzU0YjMyNjFlNjBmYTM3ZThkOWYxOTA4NjRkYiIsImlhdCI6MTcyOTcwMjA4OSwiZXhwIjoxNzI5Nzg4NDg5LCJhdWQiOiIyIiwiaXNzIjoidG9taXRydXN0bGVzc3Jld2FyZHN5c3RlbS5jb20ifQ.ZEfK4vnis6Xr780gZddGdmbD8FttgNOs4yQyL9IQ558';  // Replace 'your-access-token' with the actual token source

//         // Construct query parameters
//         const queryParams = new URLSearchParams({
//             limit,
//             offset,
//             orderField,
//             orderDirection
//         });

//         if (categoryId) {
//             queryParams.append('categoryId', categoryId); // Only include if categoryId is provided
//         }

//         try {
//             const response = await fetch(`http://52.202.190.127:3000/tasks?${queryParams.toString()}`, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${accessToken}`  // Include the access token
//                 }
//             });

//             if (response.ok) {
//                 const data = await response.json();
//                 console.log(data?.data);
                
//                 setTasks(data?.data); // Set tasks in state
//             } else {
//                 console.error('Failed to fetch tasks:', response.statusText);
//             }
//         } catch (error) {
//             console.error('Error occurred while fetching tasks:', error);
//         }
//     };

//     useEffect(() => {
//         // Example: Get 5 tasks, starting from offset 0, ordered by title in descending order
//         getTasks({ limit: 5, offset: 1, orderField: 'createdAt', orderDirection: 'ASC' });
//     }, []);
//     return (
//         <>
//             <div className="wrapper-latestsection">
//                 {
//                     history.pathname === '/latesttask' && <Navbar />
//                 }
//                 <section className={history.pathname === '/latesttask' ? "latest-task latesttask-section" : "latest-task"} id='latesttask'>
//                     <div className="custom-container">
//                         {
//                             history.pathname === '/latesttask' && <>
//                                 <div className="breadcrumb-text">
//                                     <Link to="/">Home</Link>
//                                     <span>/</span>
//                                     <Link to="/latesttask" className='active'>Tasks</Link>
//                                 </div>
//                             </>
//                         }
//                     </div>
//                     <div className="main-heading">
//                         <div className="custom-container">
//                             <div className="parent-head">
//                                 <h6>{history.pathname === '/latesttask' ? "Tasks" : "Latest Tasks"}</h6>
//                                 <Link to="/latesttask" className='btn-view'>View All Tasks</Link>
//                                 <div className="choice-bar">
//                                     <ul class="nav nav-tabs" id="myTab" role="tablist">
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">All tasks</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Music</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Marketing</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="design-tab" data-bs-toggle="tab" data-bs-target="#design-tab-pane" type="button" role="tab" aria-controls="design-tab-pane" aria-selected="false">Design</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="media-tab" data-bs-toggle="tab" data-bs-target="#media-tab-pane" type="button" role="tab" aria-controls="media-tab-pane" aria-selected="false">Media</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="crypto-tab" data-bs-toggle="tab" data-bs-target="#crypto-tab-pane" type="button" role="tab" aria-controls="crypto-tab-pane" aria-selected="false">Crypto</button>
//                                         </li>
//                                         <li class="nav-item" role="presentation">
//                                             <button class="nav-link" id="gaming-tab" data-bs-toggle="tab" data-bs-target="#gaming-tab-pane" type="button" role="tab" aria-controls="gaming-tab-pane" aria-selected="false">Gaming</button>
//                                         </li>
//                                     </ul>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="bottom-content">
//                         <div class="tab-content" id="myTabContent">
//                             <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
//                                 <div className="parent-cards">
//                                     {tasks?.tasks?.map((item, id)=>{

//                                   return ( <div className="single-card">
//                                         <img src="\assets\latesttask\right-bg-layer.png" alt="img" className='img-fluid right-bg-layer' />
//                                         <div className="custom-container">
//                                             <div className="parent">
//                                                 <div className="left-side">
//                                                     <div className="main-content">
//                                                       <h6>{item?.title}</h6>
//                                                       <p>{item?.description}</p>
//                                                     </div>
//                                                 </div>
//                                                 <div className="right-side">
//                                                     <div className="parent-box">
//                                                         <div className="upper-area">
//                                                             <div className="first-div">
//                                                                 <p>Reward</p>
//                                                                 <h6><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
//                                                                     <rect x="0.315789" y="0.315789" width="23.3684" height="23.3684" rx="11.6842" fill="#FF0083" />
//                                                                     <rect x="0.315789" y="0.315789" width="23.3684" height="23.3684" rx="11.6842" stroke="#F9F9F9" stroke-width="0.631579" />
//                                                                     <path d="M12.5003 6.46749C15.2079 6.46749 17.4151 8.59086 17.517 11.2492L19.8922 12.6944C19.9632 12.2837 20 11.8618 20 11.431C20 7.32699 16.6425 4 12.4997 4C8.35693 4 5 7.32752 5 11.4315C5 12.3201 5.15739 13.1728 5.44656 13.962C5.73253 14.7448 6.14761 15.4658 6.66619 16.1012L11.2577 13.307V19H13.6778V13.261L18.3381 16.0975C18.8561 15.4621 19.2707 14.7406 19.5561 13.9572L12.5056 9.66656L12.5003 9.66975L7.62494 12.6362C7.52944 12.2535 7.47875 11.8544 7.47875 11.4427C7.47875 8.69501 9.72702 6.46802 12.4997 6.46802L12.5003 6.46749ZM12.5003 12.5442L12.5051 12.5469L12.5003 12.5501V12.5437V12.5442Z" fill="white" />
//                                                               </svg> {item?.reward} {item?.currencySymbol}</h6>
//                                                             </div>
//                                                             <div className="first-div">
//                                                                 <p>Participants</p>
//                                                                 <div className="multi-imgs">
//                                                                     {/* <div className="imgs-parent">
//                                                                         <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
//                                                                         <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
//                                                                         <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
//                                                                         <img src="\assets\dummy\profile.png" alt="img" className='img-fluid' />
//                                                                     </div> */}
//                                                                   <span>{item?.participantCount}</span>
//                                                                 </div>
//                                                             </div>
//                                                           {/* {(item?.user?.name || item?.user?.imgUrl) && <div className="first-div">
//                                                                 <p>Created By</p>
//                                                               <h6>{item?.user?.imgUrl ?  <img src={item?.user?.imgUrl} alt="img" className='img-fluid' /> : null}{item?.user?.name || 'Null'}</h6>
//                                                             </div>} */}
//                                                           <div className="first-div">
//                                                               <p>Created By</p>
//                                                               <h6>{item?.user?.imgUrl ? <img src={item?.user?.imgUrl} alt="img" className='img-fluid' /> : null}{item?.user?.name || '...'}</h6>
//                                                           </div>
//                                                         </div>
//                                                         <Timer endTime={item?.endTime} />
//                                                         <Link to="/similartaskdetail" className='btn-part'>Participate</Link>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>)
//                                     }
//                                 ) }
//                                 </div>
//                             </div>

//                         </div>

//                     </div>
//                 </section>
//             </div>
//         </>
//     )
// }

// export default LatestTask
