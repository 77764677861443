import React, { useState, useEffect } from 'react'
import './taskscreated.scss'
import Pagination from 'react-bootstrap/Pagination';
import { Accordion } from 'react-bootstrap';
import Navbar from '../landing/header/Navbar';
import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl'
import { toast } from 'react-toastify'
import ReactPaginate from "react-paginate";
import moment from 'moment'
import { Link } from 'react-router-dom';
import Loader from '../../hooks/loader';
import Status from './Status';
const Taskscreated = () => {
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [arry, setArry] = useState([])
  const handlePageChange = (e) => {
    console.log("called");
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  const getProfile = async () => {
    let accessToken = localStorage.getItem('accessToken')
    setLoader(true);
    var config = {
      method: "get",
      url: `${API_URL}tasks/my?limit=${5}&offset=${page + 1
        }`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`  // Include the access token here
      },
    }
    try {
      let response = await axios(config)

      if (response) {
        let vale = response.data.data
        console.log('Task created successfully:', response.data.data);
        setPageCount(vale.count / 5);
        setPages(vale.pages);
        setArry(vale.tasks)
        setLoader(false);
      }
    } catch (error) {
      console.error('Error occurred while creating task:', error);
      setLoader(false);
      // toast.error(error?.message);
    }
  }

  useEffect(() => {
    getProfile()
  }, [page])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      {loader && <Loader />}
      <Navbar />
      <section className="taskscreated">
        <img src="/assets/importantassets/backshade.png" alt="backshade" className="backshade" />
        <img src="/assets/importantassets/backshadembl.png" alt="backshade" className="backshadembl d-none" />
        <div className="custom-container">
          <p className="toppara">
            <span className="light">Home </span>
            / Tasks I Created
          </p>
          <h2 className="createdhead">Tasks I Created</h2>
          <div className="createdtasktable">
            {
              arry.length > 0 ?
                <table>
                  <thead>
                    <th>Task Name</th>
                    <th>Reward</th>
                    <th>Ends in</th>
                    <th>Transaction Hash</th>
                    <th>Participants</th>
                    <th>Status</th>
                  </thead>
                  <tbody>
                    {arry?.map((e) => {
                      const targetDate = moment(e?.endTime);
                      const currentDate = moment();
                      const duration = moment.duration(targetDate.diff(currentDate));
                      let status = ''
                      const formattedDuration = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                      const currentEpochSeconds = Math.floor(Date.now() / 1000);
                      const date = new Date(e?.endTime);
                      const epochSeconds = Math.floor(date.getTime() / 1000);
                      // console.log(currentEpochSeconds < epochSeconds,e.status,'currentEpochSeconds < epochSeconds&&e.status == "inProgress"');
                      if (currentEpochSeconds < epochSeconds&&e.status == "inProgress") {
                        status = "In Progress"
                      }
                      else if(currentEpochSeconds > epochSeconds&&e.status == "inProgress") {
                        status = "In Review"
                      }
                      else if (currentEpochSeconds > epochSeconds&&e.status == "ended") {
                        status = "Ended"
                      } 
                      else {
                        status = "indispute"
                      }


                      return (
                        <tr>
                          <td>
                            <Link data-toggle="tooltip" data-placement="top" title={e?.title} to={`/tasksdetails/${e?.id}`}>
                               {e?.title}
                            </Link>
                          </td>
                          <td>
                            <p className="tablepara">{e.reward} {e.currencySymbol}</p>
                          </td>
                          <td>
                            <p className="tablepara"> {currentEpochSeconds <= epochSeconds && status !== "Ended" ? formattedDuration : " Time Ended"}</p>
                          </td>
                          <td>
                            <a  target="_blank" href={`https://etherscan.io/tx/${e.taskTransactionHash}`}>
                              hash <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M8.33333 4.66667H6.83333C5.89991 4.66667 5.43285 4.66667 5.07633 4.84832C4.76273 5.00811 4.50795 5.2629 4.34816 5.5765C4.1665 5.93302 4.1665 6.40008 4.1665 7.3335V13.6668C4.1665 14.6003 4.1665 15.0667 4.34816 15.4232C4.50795 15.7368 4.76273 15.9921 5.07633 16.1519C5.43251 16.3333 5.899 16.3333 6.83059 16.3333H13.1691C14.1007 16.3333 14.5665 16.3333 14.9227 16.1519C15.2363 15.9921 15.4919 15.7366 15.6517 15.423C15.8332 15.0668 15.8332 14.6008 15.8332 13.6692V12.1667M16.6665 8V3.83334M16.6665 3.83334H12.4998M16.6665 3.83334L10.8332 9.66667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                            </a>
                          </td>
                          <td>
                            <p className="tablepara">

                              {e.participantCount}</p>
                          </td>
                          <td>
                            {/* <p className="tablepara"><span className={status == "In Progress" ? "progressbtn" : status == "Ended" ? "endedbtn" :status=="In Review"? "reviewbtn":'disputedbtn'}>{status}</span>
                            </p> */}
                            <Status status={status} taskId={e?.contractTaskId} />
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table> :
                <>
                  <table>
                    <thead>
                      <th>Task Name</th>
                      <th>Reward</th>
                      <th>Ends in</th>
                      <th>Transaction Hash</th>
                      <th>Participants</th>
                      <th>Status</th>
                    </thead>
                    <tbody>
                      <tr><td colSpan={6}> <h6 className='nodata-text'>No Data Found!</h6></td></tr>
                    </tbody>
                  </table>
                </>
            }
            <div className="paginationmain">
              {/* <div className="left-f">
          <h6>
                {" "}
                {count > 0 && count <= 5 ? (
                  <>
                    <p>
                      SHOWING 1-{count} OF {count}
                    </p>
                  </>
                ) : count > 5 ? (
                  <>
                    <p>
                      SHOWING {page > 0 ? page * 5 + 1 : page + 1} -
                      {5 * (page + 1) > count ? count : 5 * (page + 1)} OF{" "}
                      {count}
                    </p>
                  </>
                ) : (
                  <>
                    <p>SHOWING 0 OF 0</p>
                  </>
                )}
              </h6>
          </div> */}
              {pages > 1 &&
                <ReactPaginate
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10 13L5 8L10 3"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M5 13L10 8L5 3"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={page}
                ></ReactPaginate>
              }
            </div>
          </div>
          <div className="createdtaskmbl d-none">
            <div className="acctop">
              <p className="taskpara">
                Task name
              </p>
              <p className="taskpara">
                Status
              </p>
            </div>
            {
              arry.map((e, i) => {
                const targetDate = moment(e?.endTime);
                const currentDate = moment();
                const duration = moment.duration(targetDate.diff(currentDate));
                let status = ''
                const formattedDuration = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`;
                const currentEpochSeconds = Math.floor(Date.now() / 1000);
                const date = new Date(e?.endTime);
                const epochSeconds = Math.floor(date.getTime() / 1000);
                if (currentEpochSeconds < epochSeconds&&e.status == "inProgress") {
                  status = "In Progress"
                }
                else if(currentEpochSeconds > epochSeconds&&e.status == "inProgress") {
                  status = "In Review"
                }
                else if (currentEpochSeconds > epochSeconds&&e.status == "ended") {
                  status = "Ended"
                } 
                else {
                  status = "indispute"
                }
                return (

                  <Accordion>
                    <Accordion.Item eventKey={i}>
                      <Accordion.Header>
                        <div className="taskmain">
                          <Link data-toggle="tooltip" data-placement="top" title={e?.title} to={`/tasksdetails/${e?.id}`}>
                               {e?.title.slice(0, 15)}
                            </Link>
                        </div>

                        <div className="statusmain">
                          <span className={status == "In Progress" ? "progressbtn" : status == "Ended" ? "endedbtn" :status=="In Review"? "reviewbtn":'disputedbtn'}>{status}</span>

                        </div>

                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="mainacc">
                          <div className="inneracc">
                            <p className="leftacc">Reward</p>
                            <p className="rightacc">{e.reward} {e.currencySymbol}</p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Ends in</p>
                            <p className="rightacc">{currentEpochSeconds <= epochSeconds && status !== "Ended" ? formattedDuration : " time Ended"}</p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Transaction Hash</p>
                            <p className="rightacc"><a target="_blank" href={`https://etherscan.io/tx/${e.taskTransactionHash}`}> Hash <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                              <path d="M8.33333 4.66667H6.83333C5.89991 4.66667 5.43285 4.66667 5.07633 4.84832C4.76273 5.00811 4.50795 5.2629 4.34816 5.5765C4.1665 5.93302 4.1665 6.40008 4.1665 7.3335V13.6668C4.1665 14.6003 4.1665 15.0667 4.34816 15.4232C4.50795 15.7368 4.76273 15.9921 5.07633 16.1519C5.43251 16.3333 5.899 16.3333 6.83059 16.3333H13.1691C14.1007 16.3333 14.5665 16.3333 14.9227 16.1519C15.2363 15.9921 15.4919 15.7366 15.6517 15.423C15.8332 15.0668 15.8332 14.6008 15.8332 13.6692V12.1667M16.6665 8V3.83334M16.6665 3.83334H12.4998M16.6665 3.83334L10.8332 9.66667" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></a></p>
                          </div>
                          <div className="inneracc">
                            <p className="leftacc">Participants</p>
                            <p className="rightacc">{e.participantCount}</p>
                          </div>
                        </div>
                      </Accordion.Body>

                    </Accordion.Item>
                  </Accordion>

                )
              })
            }

            <div className="paginationmain">
              {pages > 1 &&
                <ReactPaginate
                  previousLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M10 13L5 8L10 3"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  nextLabel={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <path
                        d="M5 13L10 8L5 3"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="pagination"
                  activeClassName="active"
                  forcePage={page}
                ></ReactPaginate>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Taskscreated

