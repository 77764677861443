import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const BalanceOf = () => {

  const [balance, setBalance] = useState(null)
  const { account } = useWeb3React();
  const web3 = useWeb3();
  // const contract = taskManagerContract(tokenAddress, web3);


  const BalanceOfHook = useCallback(
    async (contractAddress) => {
      const contract = usdtContract(contractAddress, web3);
      try {
        const balance = await contract.methods.balanceOf(account).call()
        
        return balance;
      } catch (error) {
        throw error;
      }
    },
    [ account,web3]
  );
  return { BalanceOfHook: BalanceOfHook };
};
export default BalanceOf;