import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import Environment from "../../utils/Environment";
import { usdtContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

export const Allowance = () => {
    const web3 = useWeb3();
    const { account } = useWeb3React();

    const AllowanceCore = useCallback(
        async (contractAddress, rewardAmount, decimal, currency) => {
            const contract = usdtContract(contractAddress, web3);

            // Convert amount based on decimals
            const amount = decimal === 6
                ? web3.utils.toWei(rewardAmount.toString(), 'mwei')         // For 6 decimal tokens
                : decimal === 8
                    ? (rewardAmount * Math.pow(10, decimal)).toString()      // For 8 decimal tokens
                    : web3.utils.toWei(rewardAmount.toString(), 'ether');   // For 18 decimal tokens (default)

            var gasFunPrice;
           

            try {
                // Check current allowance
                const currentAllowance = await contract.methods.allowance(account, Environment?.tastManager).call();
                console.log(currentAllowance, 'currentAllowance');

                // If current allowance is enough, return it
                if (parseFloat(currentAllowance) >= parseFloat(amount)) {
                    return currentAllowance;
                }

                // USDT-specific workaround: Approve with 0 first, then the actual amount
                if (currency === 'USDT' && parseFloat(currentAllowance) < parseFloat(amount) && parseFloat(currentAllowance) !== 0) {
                    // Approve 0 amount to reset the allowance
                    const gasZero = await contract.methods.approve(Environment?.tastManager, "0").estimateGas({
                        from: account,
                    });
                    web3.eth.getGasPrice().then((result) => {
                        var result2 = parseInt(result) + 3000000000
                        // // console.log("gasfun", typeof result2, result2)
                        gasFunPrice = result2.toString()
                    })
                    await contract.methods.approve(Environment?.tastManager, "0").send({
                        from: account,
                        gas: gasZero,
                        gasPrice: gasFunPrice,
                    });
                    console.log("Approved 0 amount to reset allowance for USDT.");
                }

                // Estimate gas and approve the required amount
                const gas = await contract.methods.approve(Environment?.tastManager, amount).estimateGas({
                    from: account,
                });

                // Approve the actual amount
                web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 3000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                })
                await contract.methods.approve(Environment?.tastManager, amount).send({
                    from: account,
                    gas,
                    gasPrice: gasFunPrice,
                });

                // Check allowance again after approval
                const newAllowance = await contract.methods.allowance(account, Environment?.tastManager).call();

                // If new allowance is still less than required, throw an error
                if (parseFloat(newAllowance) < parseFloat(amount)) {
                    throw new Error("Allowance is still less than the required amount after approval.");
                }

                // Return the updated allowance
                return newAllowance;
            } catch (error) {
                console.error('Error during allowance check/approval:', error);
                throw new Error("Error occurred while checking or approving allowance.");
            }
        }, [web3, account]
    );

    return { AllowanceCore };
};

export default Allowance;




// bn version
// import { useCallback } from "react";
// import useWeb3 from "../useWeb3";
// import Environment from "../../utils/Environment";
// import { usdtContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";

// export const Allowance = () => {
//     const web3 = useWeb3();
//     const { account } = useWeb3React();

//     const AllowanceCore = useCallback(
//         async (contractAddress, rewardAmount, decimal) => {
//             const contract = usdtContract(contractAddress, web3);

//             // Convert amount using 'mwei' if decimals are 6, otherwise use 'ether' for 18 decimals
//             const amount = decimal === 6
//                 ? web3.utils.toWei(rewardAmount.toString(), 'mwei')         // For 6 decimal tokens
//                 : decimal === 8
//                     ? parseFloat(rewardAmount) * Math.pow(10, parseInt(decimal))   // For 8 decimal tokens
//                     : web3.utils.toWei(rewardAmount.toString(), 'ether');   // For 18 decimal tokens (default)

//             console.log(amount, web3.utils.toBN(amount), 'amount');

//             try {
//                 // Check current allowance
//                 const currentAllowance = await contract.methods.allowance(account, Environment?.tastManager).call();
//                 const currentAllowanceBN = web3.utils.toBN(currentAllowance);
//                 console.log(currentAllowance, currentAllowanceBN, 'currentAllowanceBNcurrentAllowanceBN');

//                 // If current allowance is enough, return it
//                 if (currentAllowanceBN.gte(web3.utils.toBN(amount))) {
//                     return currentAllowanceBN;
//                 }

//                 // If allowance is insufficient, proceed with approval
//                 const gas = await contract.methods.approve(Environment?.tastManager, amount).estimateGas({
//                     from: account,
//                 });

//                 // Approve the new amount
//                 // eb3.utils.toBN(amount)
//                 await contract.methods.approve(Environment?.tastManager, amount).send({
//                     from: account,
//                     gas,
//                 });

//                 // Check allowance again after approval
//                 const newAllowance = await contract.methods.allowance(account, Environment?.tastManager).call();
//                 const newAllowanceBN = web3.utils.toBN(newAllowance);

//                 // If new allowance is still less than required, throw an error
//                 if (newAllowanceBN.lt(web3.utils.toBN(amount))) {
//                     throw new Error("Allowance is still less than the required amount after approval.");
//                 }

//                 // Return the updated allowance
//                 return newAllowanceBN;
//             } catch (error) {
//                 console.error('Error during allowance check/approval:', error);
//                 throw new Error("Error occurred while checking or approving allowance.");
//             }
//         }, [web3, account]
//     );

//     return { AllowanceCore };
// };

// export default Allowance;


// import { useCallback } from "react";
// // import { useWeb3React } from "@web3-react/core";
// import useWeb3 from "../useWeb3";
// import Environment from "../../utils/Environment";
// // import { getMarketPlaceContract, getLaunchedPadContract } from "@/utils/contractHelpers";
// import { usdtContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";



// export const Allowance = () => {
//     // const {account} = useWeb3React();
//     const web3 = useWeb3();
//     // const contractAddress = Environment?.tastManager;
//     // const contract = usdtContract(contractAddress, web3);
//     const { account } = useWeb3React();
//     const AllowanceCore = useCallback(
//         async (contractAddress, rewardAmount, decimal) => {
//             const contract = usdtContract(contractAddress, web3);
//             let amount = web3.utils.toBN(rewardAmount * Math.pow(10, decimal));
//             // let val = web3.utils.toWei(spendBnb.toString(), "ether");
//             try {
//                 const allowance = await contract.methods.allowance(account, Environment?.tastManager).call()
//                 let bal = web3.utils.fromWei(allowance, "ether")
//                 console.log(allowance, 'approveAmount allowance');
//                 const gas = await contract.methods.approve(Environment?.tastManager, amount).estimateGas({
//                     from: account,
//                 })
//                 const approveAmount = await contract.methods.approve(Environment?.tastManager, amount).send({
//                     from: account,
//                     gas,
//                 })
//                 const allowanceTwo = await contract.methods.allowance(account, Environment?.tastManager).call()
//                 if (allowanceTwo < rewardAmount){
//                     throw error 
//                 }else {
//                     return allowanceTwo;
//                 }

//                 console.log(approveAmount, 'approveAmount');
                
//                 return bal;
//             } catch (error) {
//                 // console.log('9999999', error)
//                 throw error;
//             }
//         }, [web3]
//     );
//     return { AllowanceCore: AllowanceCore };
// }
// export default Allowance;