import React from 'react'
import "./what.scss"

const What = () => {
    return (
        <>
            <section className="whatwedo" id='what'>
                <div className="main-heading">
                    <div className="custom-container">
                        <h6>What Can You Do with Tasks?</h6>
                    </div>
                </div>
                <div className="bottom-content">
                    <div className="custom-container">
                        <div className="parent">
                        <div className="single-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <rect x="8.73657" y="8.73633" width="32.5269" height="32.5269" stroke="#013159" />
                                <rect x="25" y="2" width="32.5269" height="32.5269" transform="rotate(45 25 2)" stroke="#013159" />
                                <line x1="8.73657" y1="24.8066" x2="40.6498" y2="24.8066" stroke="#013159" />
                                <line x1="25.1931" y1="9.35059" x2="25.1931" y2="41.2638" stroke="#013159" />
                                <line x1="36.471" y1="14.2364" x2="13.9997" y2="36.7077" stroke="#013159" />
                                <line x1="35.6225" y1="36.9434" x2="13.0565" y2="14.3774" stroke="#013159" />
                            </svg>
                            <h6>Creative Tasks</h6>
                            <p>Get a custom song written for you, or have a logo designed for your business.</p>
                        </div>
                        <div className="single-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <circle cx="25" cy="25" r="23" stroke="#013159" />
                                <rect x="16.72" y="9.36035" width="15.64" height="32.2" rx="7.82" stroke="#013159" />
                                <rect x="30.3948" y="8.5459" width="15.64" height="32.2" rx="7.82" transform="rotate(45 30.3948 8.5459)" stroke="#013159" />
                                <rect x="40.6396" y="17.6396" width="15.64" height="32.2" rx="7.82" transform="rotate(90 40.6396 17.6396)" stroke="#013159" />
                                <rect x="41.4536" y="31.3145" width="15.64" height="32.2" rx="7.82" transform="rotate(135 41.4536 31.3145)" stroke="#013159" />
                            </svg>
                            <h6>Professional Tasks</h6>
                            <p>Find someone to develop a website, build an app, or audit your smart contracts.</p>
                        </div>
                        <div className="single-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <circle cx="25" cy="25" r="23" stroke="#013159" />
                                <ellipse cx="25" cy="32.9613" rx="23" ry="15.0385" stroke="#013159" />
                                <ellipse cx="25" cy="42.25" rx="23" ry="5.75" stroke="#013159" />
                            </svg>
                            <h6>Real-World Tasks</h6>
                            <p>Need a specific product? Post a task and get help sourcing it or delivering it.</p>
                        </div>
                        <div className="single-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                                <rect x="16.72" y="2" width="31.28" height="31.28" stroke="#013159" />
                                <rect x="2" y="16.7197" width="31.28" height="31.28" stroke="#013159" />
                                <rect x="9.36011" y="25" width="15.64" height="15.64" stroke="#013159" />
                            </svg>
                            <h6>Big-Impact Projects</h6>
                            <p>Launch a multi-million dollar initiative, from innovation challenges to global environmental campaigns.</p>
                        </div>
                        </div>
                    </div>
                </div>

            </section>
            <section className="powerd-by">
                <img src="\assets\what\left-bg.png" alt="img" className='img-fluid left-bg' />
                <img src="\assets\what\right-bg.png" alt="img" className='img-fluid right-bg' />
                <div className="custom-container">
                    <div className="main-content">
                    <h5>Powered by the Community</h5>
                    <p>Tasks are validated by the community through a DAO. Whenever disputes arise, the community votes to determine the rightful reward recipient. In doing so, Tasks ensures transparency, fairness, and decentralization, making the system trustworthy for all users.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default What
