import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import { taskManagerContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const CreateTast = () => {

  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.tastManager;
  const contract = taskManagerContract(tokenAddress, web3);


  const createTast = useCallback(
    async (createTask, title, description, reward,  token, decimal) => {
      try {
        var gasFunPrice;
       
        let amount2;
        let amount ;
        if (decimal === 18){
          amount = web3.utils.toWei(createTask?.toString(), 'ether')
        } else if (decimal === 6){
          amount = web3.utils.toWei(createTask.toString(), 'mwei');
        }else{
          // amount = web3.utils.toWei(createTask.toString(), 'mwei');
          amount = parseFloat(createTask) * Math.pow(10, parseInt(decimal))   // For 8 decimal tokens
        }
        console.log(token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', createTask, title, description, reward,  token, 'aldflajflasjflasfjld');
        
        if (token === '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'){
          amount2 = amount
        }else{
          amount2 = 0
        }
        const gas = await contract.methods.createTask(title,description,amount,token).estimateGas({
              from: account,
          // gas,
          value: amount2
          });
        web3.eth.getGasPrice().then((result) => {
          var result2 = parseInt(result) + 3000000000
          gasFunPrice = result2.toString()
        })
        const details = await contract.methods.createTask(title,description,amount,token).send({
          from: account,
          gas,
          gasPrice: gasFunPrice,
          value: amount2
        });
        return details;
      } catch (error) {
        console.log(error,'errorerror');
        
        throw error;
      }
    },
    [contract, account]
  );
  return { createTast: createTast };
};
export default CreateTast;