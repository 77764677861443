import React, { useEffect, useState } from 'react';

const Timer = ({ endTime, onEnd }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [hasEnded, setHasEnded] = useState(false); // State to track if time has ended

    // Function to calculate the remaining time
    function calculateTimeLeft() {
        const difference = new Date(endTime) - new Date(); // Calculate time difference
        let timeRemaining = {};

        if (difference > 0) {
            timeRemaining = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        } else {
            timeRemaining = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0
            };
        }

        return timeRemaining;
    }

    // Use useEffect to update the timer every second
    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);

            // If time has ended, run any additional logic, such as callback
            if (newTimeLeft.days === 0 && newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
                clearInterval(timer); // Stop the timer once it's done
                setHasEnded(true); // Set hasEnded to true when time reaches zero
                if (onEnd) onEnd(); // Call the onEnd callback if provided
            }
        }, 1000);

        // Clear the interval when component unmounts
        return () => clearInterval(timer);
    }, [endTime]);

    return (
        <div className="mid-timer">
            <h5>{hasEnded ? "Time Ended" : "Ends In"}</h5>
            <h6>
                {timeLeft.days}D : {timeLeft.hours}H : {timeLeft.minutes}M : {timeLeft.seconds}S
            </h6>
        </div>
    );
};

export default Timer;




// import React, { useEffect, useState } from 'react';

// const Timer = ({ endTime }) => {
//     const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

//     // Function to calculate the remaining time
//     function calculateTimeLeft() {
//         const difference = new Date(endTime) - new Date(); // Calculate time difference
//         let timeRemaining = {};

//         if (difference > 0) {
//             timeRemaining = {
//                 days: Math.floor(difference / (1000 * 60 * 60 * 24)),
//                 hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
//                 minutes: Math.floor((difference / 1000 / 60) % 60),
//                 seconds: Math.floor((difference / 1000) % 60)
//             };
//         } else {
//             timeRemaining = {
//                 days: 0,
//                 hours: 0,
//                 minutes: 0,
//                 seconds: 0
//             };
//         }

//         return timeRemaining;
//     }

//     // Use useEffect to update the timer every second
//     useEffect(() => {
//         const timer = setInterval(() => {
//             setTimeLeft(calculateTimeLeft());
//         }, 1000);

//         // Clear the interval when component unmounts
//         return () => clearInterval(timer);
//     }, [endTime]);

//     return (
//         <div className="mid-timer">
//             <h5>Ends In</h5>
//             <h6>
//                 {timeLeft.days}D : {timeLeft.hours}H : {timeLeft.minutes}M : {timeLeft.seconds}S
//             </h6>
//         </div>
//     );
// };

// export default Timer;
